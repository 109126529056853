import { User as FirebaseUser } from 'firebase/auth';
import { createContext, ReactElement, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useManualQuery } from 'graphql-hooks';

import { useNextgenIntegrationContext } from '../hooks/nextgen-integration';
import { firebaseAuth, register } from './auth';

export interface FirebaseAuthContextValue {
  appUser?: AppUser;
  firebaseUser: FirebaseUser|null;
  attempted: boolean;
  authenticated: boolean;
  setRegistrationExtraData: (data: RegistrationExtraData) => void;
}

export interface AppUser {
  id: string;
  emailDomain: string;
}

export type RegistrationExtraData = Record<string, unknown>;

const FirebaseAuthContext = createContext<FirebaseAuthContextValue>({
  firebaseUser: null,
  attempted: false,
  authenticated: false,
  setRegistrationExtraData: () => {}
});

export interface FirebaseAuthContextProviderProps {
  children: ReactElement;
  getUserByFirebaseId: (firebaseId: string) => Promise<AppUser>;
  createUser: (params: CreateUserParams) => Promise<AppUser>;
  onAuthenticated?: (token: string) => void;
  dontAuthRedirect?: boolean;
}

export interface CreateUserParams extends RegistrationExtraData {
  firebaseUid: string;
  email: string;
}

export function FirebaseAuthContextProvider(props: FirebaseAuthContextProviderProps) {
  const { children, getUserByFirebaseId, createUser } = props;

  // registrationExtraData must be a ref so that its updated value
  // can be read by the time firebaseAuth.onAuthStateChanged is fired
  const registrationExtraDataRef = useRef<RegistrationExtraData>({});
  const setRegistrationExtraData = useCallback((data: RegistrationExtraData) => {
    registrationExtraDataRef.current = data;
  }, []);

  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser|null>(null);
  const [appUser, setAppUser] = useState<AppUser>();
  const [authenticated, setAuthenticated] = useState(false);
  const [attempted, setAttempted] = useState(false);
  
  const { isNextgenIntegrated } = useNextgenIntegrationContext();
  const [getNextgenUserByFirebaseUid] = useGetUserByFirebaseUid();

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (retrievedFirebaseUser: FirebaseUser|null) => {
      setFirebaseUser(retrievedFirebaseUser);

      if (!retrievedFirebaseUser) {
        setAttempted(true);
        if (isNextgenIntegrated && !props.dontAuthRedirect) {
          window.location.assign('/auth');
        }
        return;
      }
      
      if (isNextgenIntegrated) {
        const token = await retrievedFirebaseUser.getIdToken();
        props.onAuthenticated?.(token)

        const { data: existingAppUser } = await getNextgenUserByFirebaseUid({
          variables: {
            firebaseUid: retrievedFirebaseUser.uid
          }
        });
        if (existingAppUser.userOfFirebaseUid) {
          setAppUser(existingAppUser.userOfFirebaseUid);
          setAuthenticated(true);
          setAttempted(true);
          return;
        }
      } else {
        const existingAppUser = await getUserByFirebaseId(retrievedFirebaseUser.uid);
        if (existingAppUser) {
          setAppUser(existingAppUser);
          setAuthenticated(true);
          setAttempted(true);
          return;
        }
      }

      const createdAppUser = await createUser({
        firebaseUid: retrievedFirebaseUser.uid,
        email: retrievedFirebaseUser.email!,

        // we use registrationExtraData here so that the registration
        // API calls are contained within this file
        ...registrationExtraDataRef.current
      });

      setAppUser(createdAppUser);
      setAuthenticated(true);
      setAttempted(true);
    });
  }, [createUser, getUserByFirebaseId, getNextgenUserByFirebaseUid]);

  const value = {
    firebaseUser,
    appUser,
    attempted,
    authenticated,
    setRegistrationExtraData
  };

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(FirebaseAuthContext);
}

export function useRegister() {
  const { setRegistrationExtraData } = useAuth();
  return useCallback((email: string, password: string, extraData?: RegistrationExtraData) => {
    if (extraData) {
      setRegistrationExtraData(extraData);
    }
    return register(email, password);
  }, [setRegistrationExtraData]);
}

export function useGetUserByFirebaseUid() {
  return useManualQuery(`
    query userByFirebaseUid($firebaseUid: String!) {
      userOfFirebaseUid(firebaseUid: $firebaseUid) {
        id
      }
    }
  `);
}
