import { MouseEvent, useState, useCallback } from 'react';

// this allows you to "control" the menu open-state from the outside
export function useMenuController() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isOpen = Boolean(anchorEl)

  return {
    isOpen,
    anchorEl,
    openMenu,
    closeMenu
  };
}
