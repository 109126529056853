import React, { HTMLAttributes } from 'react';

export type LogoProps = Partial<HTMLAttributes<HTMLImageElement>>;

export function Logo(props: LogoProps) {
  return (
    <img
      style={{
        height: 30,
        ...props.style
      }}
      {...props}
      src="/eh-logo-grn.svg"
    />
  );
}
