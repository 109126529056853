import { makeStyles } from '@material-ui/core/styles';

export const useAuthFormLayoutStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 56px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
