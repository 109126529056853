import React, { useState } from 'react';
import { Link, Typography } from '@material-ui/core';

import { LoginForm, LoginProps } from './login-form';
import { RegisterForm, RegisterFormProps } from './register-form';
import { InitForgotPasswordResetForm, InitForgotPasswordResetFormProps } from './init-forgot-password-reset-form';
import { useAuthFormStyles } from './auth-form.styles';
import { DataPrivacyNotice, DataPrivacyHeading } from './data-privacy-notice';

export interface AuthFormProps {
  logoUrl: string;
  login: LoginProps['submit'];
  onLoginSuccess?: LoginProps['onSubmitSuccess'];
  register: RegisterFormProps['submit'];
  isRegisterPending: RegisterFormProps['isSubmitting'];
  registrationError: RegisterFormProps['error'];
  clearRegistrationError: () => void;
  triggerPasswordReset?: InitForgotPasswordResetFormProps['submit'];
  checkEmailAddress: (emailAddress: string) => Promise<boolean>;
}

export enum AuthFormMode {
  Login = 'login',
  Register = 'register',
  ResetPassword = 'resetPassword'
}

export function AuthForm(props: AuthFormProps) {
  const [mode, setMode] = useState(AuthFormMode.Register)

  const classes = useAuthFormStyles();

  return (
    <div>
      <img
        src={props.logoUrl}
        alt="Electric Hydrogen Logo"
        className={classes.logo}
      />

      {(mode === AuthFormMode.Login || mode === AuthFormMode.Register) && (
        <Typography className={classes.prompt}>
          To access the <span className={classes.promptEmphasis}>EH2 Analytics suite</span>, please sign-in or create a new account
        </Typography>
      )}

      {mode === AuthFormMode.Login && (
        <>
          <LoginForm
            submit={props.login}
            onSubmitSuccess={props.onLoginSuccess}
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link
              component="button"
              onClick={() => setMode(AuthFormMode.Register)}
            >Register</Link>

            <Link
              component="button"
              onClick={() => setMode(AuthFormMode.ResetPassword)}
            >Reset password</Link>
          </div>
        </>

      )}

      {mode === AuthFormMode.Register && (
        <>
          <RegisterForm
            submit={props.register}
            isSubmitting={props.isRegisterPending}
            error={props.registrationError}
            checkEmailAddress={props.checkEmailAddress}
          />

          <Link
            component="button"
            onClick={() => {
              // todo this is the downside of ui not managing its async state
              props.clearRegistrationError();
              setMode(AuthFormMode.Login);
            }}
          >Already have an account? Login</Link>
        </>
      )}

      {mode === AuthFormMode.ResetPassword && (
        <>
          <InitForgotPasswordResetForm
            submit={props.triggerPasswordReset}
          />

          <Link
            component="button"
            onClick={() => setMode(AuthFormMode.Login)}
          >Back to login</Link>
        </>
      )}

      {(mode === AuthFormMode.Register || mode === AuthFormMode.Login) && (
        <DataPrivacyHeading showTooltip />
      )}
    </div>
  );
}
