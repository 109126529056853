import { makeStyles } from '@material-ui/core/styles';

import { eh2green } from '~/common/styles/constants';

export const useAuthFormStyles = makeStyles(theme => ({
  logo: {
    width: 200,
    margin: '0 auto',
    display: 'block',
    marginBottom: theme.spacing(2)
  },
  prompt: {
    width: 280,
    marginBottom: 24,
    textAlign: 'center',
  },
  promptEmphasis: {
    fontWeight: 'bold',
    color: eh2green
  },
  dataPrivacy: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  dataPrivacyIcon: {
    width: 60,
  },
  dataPrivacyTooltip: {
    background: 'none'
  },
  dataPrivacyHeadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 24,
  },
  dataPrivacyContent: {
    position: 'relative',
    left: -4,
    width: 400,
    background: '#cce6d5',
    color: '#000',
    padding: 24,
    paddingTop: 8,
    borderRadius: 4,
    border: 'solid 1px #ccc'
  },
  dataPrivacyMainText: {
    marginTop: 12,
    fontSize: 12,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    gap: 12
  },
  dataPrivacyExcerptText: {
    fontStyle: 'italic',
    marginLeft: 16,
    fontWeight: 'normal'
  },
}));
