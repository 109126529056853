import React from 'react';
import { Typography, TypographyProps, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

export interface TruncatedEmailProps extends TypographyProps {
  email: string;
}

export function TruncatedEmail(props: TruncatedEmailProps) {
  const { email, ...rootProps } = props;

  const classes = useTruncatedEmailStyles();

  const [name, domain] = props.email.split('@');
  if (!name || !domain) {
    return null;
  }

  return (
    <Tooltip title={props.email}>
      <span>
        <Typography {...rootProps} className={clsx(classes.root, rootProps.className)}>
        <span className={classes.name}>{name}</span>
        <span>@{domain}</span>
      </Typography>
      </span>
    </Tooltip>
  );
}


const useTruncatedEmailStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block'
  }
}));
