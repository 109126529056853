import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Collapse } from '@material-ui/core';
import { eh2green } from '~/common/styles/constants';

export interface NewFeaturesDialogProps {
  
}

export const viewkey = 'seen_v4_new_features_dialog';

export function NewFeaturesDialog(props: NewFeaturesDialogProps) {
  const [seen, setSeen] = useState(localStorage.getItem(viewkey) === 'true');
  const close = () => {
    localStorage.setItem(viewkey, 'true');
    setSeen(true);
  };
  const shown = !seen;
  
  const [listShown, setListShown] = useState(false);
  
  return (
    <Dialog open={shown} onClose={close}>
      <DialogTitle>Welcome back to EH2 LCOH+</DialogTitle>
      
      <DialogContent>
        <Typography>
          You can explore the features from the latest update before diving into your green H₂ modeling project.
          
          <Collapse in={listShown}>
            <ul>
              <li>Refined H₂ compression modeling. Now the H₂ produced is split between a first stream that feeds directly the offtake at a desired discharge pressure, and a split stream for further compression and storage (if needed) </li>
              <li>When H₂ storage is modeled for firming H₂ supply, users can now see the offtaking process’s input capacity</li>
              <li>A plant AC-to-DC capacity ratio is provided now as an input</li>
              <li>Updated H₂ compression cost data.</li>
              <li>Fixed an H₂ storage bug when user chooses "I know the H₂ output" and "Peak [hourly]"</li>
              <li>User-defined storage capacity switched to metric tons of storage capacity rather than number of days. Number of days was not always clear to users and a less ambiguous metric is now used.</li>
              <li>Fixed result card bug for "Enough H₂ for"</li>
              <li>Unit CapEx is changed back to $/kW DC as this DC based unit is most used today by the industry.</li>
              <li>Stack replacement capex in $/kW DC</li>
              <li>Minor adjustment to electrolyzer degradation calculation. Degradation is now taking into account discrete electrolysis plant units</li>
              <li>See more features in the <a href="/lcoh+/changelog" target="_blank">LCOH+ changelog</a></li>
            </ul>
          </Collapse>
        </Typography>
        
      </DialogContent>
      
      <DialogActions>
        <Button onClick={close} variant="outlined">
          {listShown ? 'DISMISS' : 'SKIP'}
        </Button>
        {!listShown && (
          <Button onClick={() => setListShown(true)} variant="contained" style={{ background: eh2green, color: 'white' }}>EXPLORE FEATURES</Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
