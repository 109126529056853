import { ButtonProps, CircularProgress } from '@material-ui/core';

export interface GetPendingButtonPropsParams {
  invalid?: boolean;
  pending?: boolean;
}

export interface PendingButtonPropsResult {
  disabled: ButtonProps['disabled'];
  endIcon: ButtonProps['endIcon']
}

export function getPendingButtonProps(params: GetPendingButtonPropsParams): PendingButtonPropsResult {
  return {
    disabled: params.pending || params.invalid,
    endIcon: params.pending ? <CircularProgress size={12} /> : undefined
  }
}
