import { makeStyles } from '@material-ui/core/styles';

export const useSelectStyles = makeStyles(theme => ({
  tooltip: {
    position: 'absolute',
    right: -7,
    top: -8,
    zIndex: 1,
    background: theme.palette.background.default
  }
}));
