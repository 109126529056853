import { createContext, PropsWithChildren, useContext } from 'react';

export interface NextgenIntegrationContextValue {
  isNextgenIntegrated: boolean;
  lcohplusPermissionName: string;
} 

const NextgenIntegrationContext = createContext<undefined|NextgenIntegrationContextValue>(undefined);

export interface NextgenIntegrationContextProviderProps extends PropsWithChildren<unknown> {
  isNextgenIntegrated: boolean;
}

export function NextgenIntegrationContextProvider(props: NextgenIntegrationContextProviderProps) {
  const lcohplusPermissionName = props.isNextgenIntegrated ? 'lcohplus' : 'dlcoh';

  return (
    <NextgenIntegrationContext.Provider 
      value={{ 
        isNextgenIntegrated: props.isNextgenIntegrated,
        lcohplusPermissionName
      }}
    >
      {props.children}
    </NextgenIntegrationContext.Provider>
  )
}

export function useNextgenIntegrationContext() {
  const ctx = useContext(NextgenIntegrationContext);
  if (!ctx) {
    throw new Error('NextgenIntegrationContext is empty');
  }
  return ctx;
}
