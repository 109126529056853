import { makeStyles } from '@material-ui/core/styles';
import { eh2green } from '~/common/styles/constants';
import { addAlpha } from '~/common/util/color';

export const useModelNumberFieldStyles = makeStyles(theme => ({
  muiTextField: {
    '& .MuiFormLabel-root': {
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: 20
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 8
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 8
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: 4
    },
    '& input': {
      minWidth: 27
    }
  },

  inputStartAdornment: {
    '& .MuiTypography-root': {
      fontSize: 12,
      position: 'relative',
      top: 1
    },
  },

  inputSuffix: {
    fontSize: 12,
    color: theme.palette.grey['700'] // todo: if derived, make it lighter
  },

  insightIcon: {
    color: "green",
    width: 20,
    height: 20,
    position: "absolute",
    cursor: "default",
    top: "calc(50% - 9px)",
    left: "6ch",
  },

  insightTooltip: {
    background: addAlpha(eh2green, 0.85),
  },

  fieldInfoIcon: {
    fontSize: 18,
    color: 'cornflowerblue',
    position: 'absolute',
    top: -8,
    right: -7,
    cursor: 'help',
    background: theme.palette.background.default,
    zIndex: 1,
    borderRadius: 50
  },

  helperText: {
    marginTop: -10,
    background: theme.palette.background.default,
    zIndex: 1,
    width: 'fit-content'
  },

  derived: {
    background: '#eee'
  },
}));
