import React from 'react';

import { CompleteRegistrationForm, AuthFormLayout } from '~/common/components';

import { getRegistrationEmailAddress, completeRegistration, useAuth } from '../../auth';

export interface CompleteRegistrationContainerProps {
  onAlreadyAuthenticated: () => void;
  onMissingEmail: () => void;
  onSuccess: () => void;
}

export function CompleteRegistrationContainer(props: CompleteRegistrationContainerProps) {
  const { firebaseUser: user } = useAuth();
  if (user) {
    props.onAlreadyAuthenticated();
  }

  if (typeof window === 'undefined') {
    return null;
  }

  const email = getRegistrationEmailAddress();
  if (!email) {
    props.onMissingEmail();
    return null;
  }

  const submit = (password: string) => {
    return completeRegistration(email as string, password)
  }

  return (
    <AuthFormLayout>
      <CompleteRegistrationForm
        logoUrl="/eh-logo.png"
        email={email}
        submit={submit}
        onSubmitSuccess={props.onSuccess}
      />
    </AuthFormLayout>
  )
}
