import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { Tooltip, TooltipProps } from "@material-ui/core";
import Image from "next/image";

import { useInsightTooltipStyles } from "./insight-tooltip.styles";

export type InsightTooltipProps = Omit<TooltipProps, "children" | "classes"> & {
  classes?: InsightTooltipClasses;
  startOpen?: Boolean;
  inputFocused?: Boolean;
};

export type InsightTooltipClasses = TooltipProps["classes"];

export function InsightTooltip(props: InsightTooltipProps) {
  const tooltipRef = useRef(null);
  const [inView, setInView] = React.useState(false);

  const callback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    entry.isIntersecting ? setInView(true) : setInView(false);
  };

  useEffect(() => {
    if (props.inputFocused === true) {
      setOpen(false);
    }
  }, [props.inputFocused]);

  // Hide when not in view
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
    };
    const ref = tooltipRef.current;
    const observer = new IntersectionObserver(callback, options);

    if (ref) observer.observe(ref);

    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tooltipRef]);

  const classes = useInsightTooltipStyles();
  const [open, setOpen] = useState(Boolean(props.startOpen));
  return (
    <Tooltip
      {...props}
      ref={tooltipRef}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onClick={() => setOpen(false)}
      PopperProps={{ onClick: () => setOpen(false) }}
      interactive
      classes={{
        tooltip: clsx([
          classes.insightTooltip,
          inView === false && classes.hidden,
        ]),
        ...props.classes,
      }}
    >
      <div className={classes.insightIcon} style={props.style}>
        <Image
          alt="insight"
          src="/images/lightbulb.svg"
          width="100%"
          height="100%"
        />
      </div>
    </Tooltip>
  );
}
