import React from 'react';

import { AuthFormLayout, ChangePasswordForm } from '~/common/components';

import { resetPassword } from '../../auth';

export interface ResetPasswordContainerProps {
  oobCode: string;
  onMissingOobCode: () => void;
  onSuccess: () => void;
}

export function ForgotPasswordContainer(props: ResetPasswordContainerProps) {
  if (!props.oobCode) {
    props.onMissingOobCode();
    return null;
  }

  const submit = (newPassword: string) => {
    return resetPassword(props.oobCode, newPassword);
  };

  return (
    <AuthFormLayout>
      <ChangePasswordForm
        requireOldPassword={false}
        logoUrl="/eh-logo.png"
        submit={submit}
        onSuccess={props.onSuccess}
      />
    </AuthFormLayout>
  )
}
