import React from 'react';

import { AuthForm, AuthFormLayout } from '~/common/components';

import { useAuth, login, sendResetPasswordEmail } from '../../auth';
import { checkEmailAddress } from '../../service';

import { useRegistration } from './auth-form.model';

export interface AuthFormContainerProps {
  onAlreadyAuthenticated?: () => void;
  onLoginSuccess?: () => void;
  onRegistrationSuccess?: () => void;
}

export function AuthFormContainer(props: AuthFormContainerProps) {
  const { firebaseUser } = useAuth();
  const { register, errorMessage, clearErrorMessage, asyncState: registrationState } = useRegistration();

  if (firebaseUser) {
    props.onAlreadyAuthenticated?.();
    return null;
  }

  const triggerPasswordReset = (email: string) => {
    return sendResetPasswordEmail(email, `${window.location.origin}/reset-password`);
  }

  return (
    <AuthFormLayout>
      <AuthForm
        logoUrl="/eh-logo.png"
        login={login}
        onLoginSuccess={props.onLoginSuccess}
        register={register}
        isRegisterPending={registrationState.isPending}
        registrationError={errorMessage}
        clearRegistrationError={clearErrorMessage}
        triggerPasswordReset={triggerPasswordReset}
        checkEmailAddress={checkEmailAddress}
      />
    </AuthFormLayout>
  );
}
