import React from 'react';

import { ChangePasswordForm, AuthFormLayout } from '~/common/components';
import { ChassisV2Container } from '~/app/containers/chassis';

import { changePassword } from '../../auth';

export interface ChangePasswordContainerProps {
  onSuccess: () => void;
}

export function ChangePasswordContainer(props: ChangePasswordContainerProps) {
  const handleSubmit = async (newPassword: string, oldPassword: string) => {
    return changePassword(newPassword, oldPassword)
  }

  return (
    <ChassisV2Container title="Change Password">
      <AuthFormLayout>
        <ChangePasswordForm
          logoUrl="/eh-logo.png"
          requireOldPassword
          submit={handleSubmit}
          onSuccess={props.onSuccess}
        />
      </AuthFormLayout>
    </ChassisV2Container>
  );
}
