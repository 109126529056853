export function TosContent() {
  return (
    <div dangerouslySetInnerHTML={{ __html: tos() }}/>
  )
}

function tos() {
  return `
<html xmlns:ns0="urn-legalmacpac-data/10">

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
\t{font-family:Wingdings;
\tpanose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
\t{font-family:"MS Mincho";
\tpanose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
\t{font-family:"Cambria Math";
\tpanose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
\t{font-family:DFKai-SB;}
@font-face
\t{font-family:Tahoma;
\tpanose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
\t{font-family:Garamond;
\tpanose-1:2 2 4 4 3 3 1 1 8 3;}
@font-face
\t{font-family:"\\@DFKai-SB";}
@font-face
\t{font-family:"\\@MS Mincho";
\tpanose-1:2 2 6 9 4 2 5 8 3 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
\t{margin:0in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
a:link, span.MsoHyperlink
\t{color:blue;
\ttext-decoration:underline;}
p.BodyTextContinued, li.BodyTextContinued, div.BodyTextContinued
\t{mso-style-name:"Body Text Continued";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
p.StandardCont1, li.StandardCont1, div.StandardCont1
\t{mso-style-name:"Standard Cont 1";
\tmso-style-link:"Standard Cont 1 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:.5in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
span.StandardCont1Char
\t{mso-style-name:"Standard Cont 1 Char";
\tmso-style-link:"Standard Cont 1";
\tfont-family:"Times New Roman",serif;}
p.StandardL1, li.StandardL1, div.StandardL1
\t{mso-style-name:Standard_L1;
\tmso-style-link:"Standard_L1 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:0in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
span.StandardL1Char
\t{mso-style-name:"Standard_L1 Char";
\tmso-style-link:Standard_L1;
\tfont-family:"Times New Roman",serif;}
p.StandardL2, li.StandardL2, div.StandardL2
\t{mso-style-name:Standard_L2;
\tmso-style-link:"Standard_L2 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:.5in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
p.StandardL3, li.StandardL3, div.StandardL3
\t{mso-style-name:Standard_L3;
\tmso-style-link:"Standard_L3 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:1.0in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
p.StandardL4, li.StandardL4, div.StandardL4
\t{mso-style-name:Standard_L4;
\tmso-style-link:"Standard_L4 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:1.5in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
p.StandardL5, li.StandardL5, div.StandardL5
\t{mso-style-name:Standard_L5;
\tmso-style-link:"Standard_L5 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:2.0in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
p.StandardL6, li.StandardL6, div.StandardL6
\t{mso-style-name:Standard_L6;
\tmso-style-link:"Standard_L6 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:2.5in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
p.StandardL7, li.StandardL7, div.StandardL7
\t{mso-style-name:Standard_L7;
\tmso-style-link:"Standard_L7 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:3.0in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
p.StandardL8, li.StandardL8, div.StandardL8
\t{mso-style-name:Standard_L8;
\tmso-style-link:"Standard_L8 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:3.5in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
p.StandardL9, li.StandardL9, div.StandardL9
\t{mso-style-name:Standard_L9;
\tmso-style-link:"Standard_L9 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:0in;
\ttext-align:justify;
\ttext-justify:inter-ideograph;
\ttext-indent:4.0in;
\tfont-size:11.0pt;
\tfont-family:"Times New Roman",serif;}
.MsoChpDefault
\t{font-family:"Calibri",sans-serif;}
.MsoPapDefault
\t{text-autospace:none;}
 /* Page Definitions */
 @page WordSection1
\t{size:8.5in 11.0in;
\tmargin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
\t{page:WordSection1;}
 /* List Definitions */
 ol
\t{margin-bottom:0in;}
ul
\t{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-bottom:12.0pt;text-align:center'><a
name="_mps949752400000000000000004054000000000"><b><span style='text-transform:
uppercase'>eh2 Terms of Use</span></b></a></p>

<p class=MsoNormal align=center style='margin-bottom:12.0pt;text-align:center'>Last
Updated Date: July 6, 2022</p>

<p class=BodyTextContinued>This Terms of Use Agreement (the “<b><i>Terms</i></b>”)
is an agreement between Electric Hydrogen Co. and its affiliates (“<b><i>EH2</i></b>”,
“<b><i>we</i></b>”, and “<b><i>us</i></b>”) and you, and governs your use of
and access to (i) the websites available at <a href="https://eh2.app/lcoh">https://eh2.app/lcoh</a>
and <a href="https://eh2.app/lcoh+">https://eh2.app/lcoh+</a> (the “<b><i>Website</i></b>”),
(ii) the applications, including but not limited to LCOH and LCOH+ (the “<b><i>Applications</i></b>”),
and (iii) the services or other resources available on or enabled via the
Website or the Applications (collectively, with our Website and Applications,
the “<b><i>Services</i></b>”). Please read these Terms carefully as they affect
your rights.  </p>

<p class=BodyTextContinued>BY ACCESSING  OR USING OUR SERVICES IN ANY WAY, BY
CLICKING ON THE “I ACCEPT” BUTTON, COMPLETING THE REGISTRATION PROCESS AND/OR
BROWSING THE WEBSITE OR USING AN EH2 APPLICATION, YOU REPRESENT THAT (1) YOU
HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS, (2) YOU ARE OF LEGAL
AGE TO FORM A BINDING CONTRACT WITH EH2, AND (3) YOU HAVE THE AUTHORITY TO
ENTER INTO THE TERMS PERSONALLY OR ON BEHALF OF THE ENTITY YOU HAVE NAMED AS
THE USER, AND TO BIND THAT ENTITY TO THE TERM.  THE TERM “<b>YOU</b>” AND “<b>YOUR</b>”
REFERS TO YOU AS AN INDIVIDUAL AND ANY LEGAL ENTITY THAT YOU HAVE NAMED AS A
USER AT THE TIME OF REGISTRATION FOR THE SERVICES.  <b>IF YOU DO NOT AGREE TO
BE BOUND BY THESE TERMS YOU MAY NOT ACCESS OR USE THE SERVICES.</b></p>

<p class=BodyTextContinued>These Terms will remain in effect at all times while
you use the Services or any user output, including data, designs, simulations,
diagrams, renderings, calculations, cost estimates or models obtained through
use of or access to the Services (collectively, “<b><i>User Output</i></b>”).
These Terms govern your access to the Services and use of User Output on EH2’s
websites, applications, and other properties and include the provisions in this
document, as well as those in the Privacy Policy.</p>

<p class=BodyTextContinued>We may modify these Terms at any time and will
notify you of any changes by posting a notice on our Website. We will also
update the “Last Updated” date at the top of the Terms. Your continued use of
the Website, the Applications and/or Services after these Terms have changed
constitutes your acceptance of the revised Terms. If you do not agree to the
changes, you should stop using the Services.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Services</u></b></p>

<p class=BodyTextContinued>The EH2 Services enables users of the Services (“<b><i>Users</i></b>”)
to assess the economics of producing hydrogen using water electrolysis coupled
with renewable power. You agree that any usage by you of the Services shall be
solely for the purposes of running simulations, configurations or
characterizations of a project site or potential site (i) to aid in your
project site selection and evaluation or (ii) for your personal or your
organization’s purposes (including downloading, and making and distributing
copies of, such generated simulations/configurations exclusively in furtherance
of this authorized purpose).</p>

<p class=BodyTextContinued>Subject to your compliance with these Terms, EH2
grants you a limited non-exclusive, non-transferable, non-sublicensable,
revocable license to the Services, including the Website and Applications
solely for your own personal or internal business purposes. Unless otherwise
specified by a separate license, your right to use any and all of the Services
is subject to these Terms.  </p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Access and Security</u></b></p>

<p class=BodyTextContinued>As part of using the Services or accessing and
interacting with User Output, we may require you to sign up for an account on
the Website and select a password and email or username (“<b><i>User ID</i></b>”).
In registering an account, you agree to provide us with accurate, complete, and
updated registration information about yourself as prompted by the registration
form. Your right to access the Services is personal to you and you may not
transfer your account to anyone else without our prior written permission. You
will not share your account or password with anyone, and you must protect the
security of your account and your password. You may not select as your User ID
a name that you do not have the right to use, or another person’s or entity’s
name with the intent to impersonate that person or entity. You are responsible
for any activity associated with your account. You are responsible for
maintaining the confidentiality of your account and your password. You
represent and warrant that you are of legal age to form a legally binding
contract and not a person barred from using the Services under the laws of the
United States or any other applicable jurisdiction. </p>

<p class=BodyTextContinued>Our websites are controlled and operated by us from
the United States, and, except as expressly set forth herein, are not intended
to subject us to the laws or jurisdiction of any state, country or territory
other than that of the United States. We do not represent or warrant that the
Services or any part thereof are appropriate or available for use in any
particular jurisdiction other than the United States. In choosing to access the
Services, you do so on your own initiative and at your own risk, and are
responsible for complying with all local laws, rules and regulations. You are
also subject to U.S. export controls and are responsible for any violations of
such controls, including any U.S. embargoes or other federal rules and
regulations restricting exports. EH2 may limit the Services’ availability, in
whole or in part, to any person, geographic area or jurisdiction EH2 chooses,
at any time and in our sole discretion. By using the Services, you hereby
certify that you are not (a) a citizen or permanent resident of any country on
which the United States has embargoed goods, technology and/or services (e.g.,
Cuba, Iran, North Korea, Sudan, Syria, or Crimea Region of Ukraine), and (b) on
any of the relevant U.S. Government Lists of prohibited or restricted persons,
including but not limited to the Treasury Department's List of Specially
Designated Nationals, and the Commerce Department's List of Denied Persons or
Entity List, and that your use of our products and services is in compliance
with the applicable U.S. export control and economic sanctions laws and
regulations.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Certain Restrictions</u></b></p>

<p class=BodyTextContinued>You agree that you will not provide any Customer
Data (as defined in these Terms) or other materials or otherwise use the
Services or interact with the Services in any manner that: (a) infringes upon,
misappropriates or violates the intellectual property rights or any other
rights of EH2 or any third party; (b) violates any applicable laws, rules or
regulations, including any applicable export control laws, or would cause EH2
to be in violation of any applicable laws, rules or regulations; (c) violates
any agreement to which you are subject (including but not limited to any
confidentiality or employment agreement); (d) is threatening, harassing,
degrading, hateful, defamatory, fraudulent, tortious, obscene, indecent or
objectionable; (e) jeopardizes or interferes with the security of the Services,
your EH2 account or anyone else’s account; (f) attempts, in any manner, to
obtain the password, account, or other security information from any other
user; (g) violates or interferes with the security of any computer network, or
cracks any passwords or security encryption codes; (h) transmits, distributes
or enables any virus, worm, Trojan horse or other code or program that is
harmful or invasive or that is intended to damage or hijack the operation of
any hardware or software; attempts to disrupt, interfere with or slow down our
delivery of Services, including but not limited to a denial of service attack;
(i) “crawls,” indexes, “scrapes,” or “spiders” any page, data, or portion of or
relating to the Services or User Output (through use of manual or automated
means); (j) circumvents or attempts to circumvent any features, limitations, or
restrictions of the Services (including, without limitation, attempting to
access, download, export, or otherwise use or exploit any User Output using any
automated means or tools or to attempt to obtain paid services without making
payment); (k) runs any form of auto-responder or “spam” on the Services, or any
processes that run or are activated while you are not logged into the Services,
or that otherwise interfere with the proper working of the Services (including
by placing an unreasonable load on the Services’ infrastructure); (l) copies or
stores any significant portion of User Output in any manner not expressly
permitted by the Terms (e.g., creating a database by systematically downloading
or storing User Output); or (m) decompiles, reverse engineers, translates,
creates derivative works from, obtains, or reproduces any code or underlying
ideas, inventions, technology, algorithms or functionality of or relating to
the Services or User Output, or attempts to do so.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Customer Data</u></b></p>

<p class=BodyTextContinued>The Services provide opportunities for you to
provide data, content, files or other information (collectively, “<b><i>Customer
Data</i></b>”) to us in connection with your use of the Services. When you
upload any Customer Data, you represent that you own all the rights and/or have
a license to that Customer Data. You do not transfer ownership of your Customer
Data simply by providing it to us. For the avoidance of doubt, Customer Data
does not include User Output. </p>

<p class=BodyTextContinued>Your Customer Data is owned by you or the respective
licensors or property owners.<b> </b>By providing us with Customer Data, you hereby
grant, on your own behalf and on the behalf of such licensors or property
owners, to EH2 and its affiliates the right to use Customer Data (a) to provide
Services to you; (b) for the purpose of system maintenance, including but not
limited to implementing and verifying EH2 security maintenance/upgrades, and
for maintenance of the Services; (c) on an aggregated basis (and not in a
manner that identifies individual users/projects) for the purpose of improving
the Services; and (d) on an aggregated basis (and not in a manner that
identifies individual users/projects) for our research on EH2 customer and
project demographics, interests, and behavior and for marketing and business
development purposes.</p>

<p class=BodyTextContinued>If you use the Services to communicate or share User
Output with other Users, including as a buyer or seller of materials,
equipment, services or supplies relating to energy projects, EH2 may share with
such Users certain Customer Data as required to provide the Services, including
your name, company name (if any), addresses (including e-mail address) and
phone number, to the extent that you first authorize us to do so. EH2 is not
responsible for any access to, use, transfer or security of data or information
by other Users to whom you have granted access. You are solely responsible for
understanding the security and privacy risk and compliance considerations of
your decision to share Customer Data with other Users. The use, security and
custodianship of any Customer Data, once shared with other Users, is the
responsibility of those Users. It is your responsibility to take prudent
measures to protect your data and information that is in the control of other
Users, such as by entering into non-disclosure agreements with them.</p>

<p class=BodyTextContinued>If you choose to use Third-Party Services (as
defined below) in connection with the Services, you hereby grant EH2 permission
to allow such Third-Party Services and their providers access to Customer Data
and information about your usage of such Third-Party Services in connection
with the Services.</p>

<p class=BodyTextContinued>If you cease using the Services to communicate or
share User Output with other Users and revoke our authorization to share your Customer
Data with other Users, they will no longer be able to access such User Output.
Users who have already received your Customer Data are responsible for deleting
such information from their systems in accordance with their policies. Please
contact them directly to ensure that your data and information has been
deleted.</p>

<p class=BodyTextContinued><span style='text-transform:uppercase'>Your Customer
Data and the user output provided to you through your use of our Services, will
be treated by EH2 as confidential, except to the extent that you, or a
representative from your organization having the appropriate administrative
authority, authorize us to disclose it to other Users. Such information will
not be disclosed by EH2 or its affiliates to any party for any purpose not
permitted by these Terms; provided, that we may disclose such information for
the purpose of complying with the order or requirement of a court,
administrative agency or other governmental body or other applicable legal
requirement and provided further that we will use commercially reasonable
efforts to notify you in advance of any such disclosure.</span></p>

<p class=BodyTextContinued>You agree that you will not knowingly, intentionally
or recklessly provide any Customer Data that is not accurate, true and complete
and agree that you will make reasonable efforts to maintain and update such
information as appropriate. You acknowledge that EH2 is not responsible in any
respect for the accuracy, truth, or completeness of any Customer Data. You
agree to indemnify, release, and hold us harmless from any and all liability,
claims, actions, loss, harm, damage, injury, cost or expense arising out of any
Customer Data you provide.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>User Output</u></b></p>

<p class=StandardL1>Subject to the restrictions in these Terms, you retain
ownership of all User Output generated by your use or access to the Services. You
hereby grant EH2 and its affiliates a worldwide, fully-paid, royalty-free,
transferable, sub-licensable, and perpetual right to use User Output for any
business purpose, including on an aggregated basis (and not in a manner that
identifies individual users/projects) for (a) improving the Services; (b) our
research on EH2 customer and project demographics, interests, and behavior; and
(c) marketing and business development purposes.</p>

<p class=StandardCont1 style='text-indent:0in'>Your use of User Output is
restricted to your own personal or internal business purposes. You may
reference the User Output in a publication or marketing material, with proper
attribution to EH2. You may distribute the User Output to third-parties,
provided that any such distributions prominently display the EH2 name and logo.
Any other uses of the User Output require EH2’s prior written consent.</p>

<p class=StandardCont1 style='text-indent:0in'>EH2 is not obligated to backup
any User Output, and your User Output may be deleted at any time without prior
notice.  You are solely responsible for creating and maintaining your own
backup copies of your User Output if you desire.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Intellectual Property; Feedback</u></b></p>

<p class=BodyTextContinued>The Services constitute the property of EH2 and/or EH2’s
suppliers and licensors, as applicable, and are protected by copyright,
trademark, patent and/or other proprietary rights and laws. EH2 and/or its
suppliers and licensors retain all of their respective intellectual property
rights in the Services and no such rights are conveyed to you by these Terms or
your use of the Services other than the limited rights expressly granted to you
in these Terms. Any reproduction, transmission, publication, transfer, resale,
creation of derivative works from, redistribution or other use or exploitation
of the Services by you not expressly in accordance with these Terms, including
without limitation any effort to resell or distribute all or any part of any
Services or any technology or functionality underlying the Services, is
expressly prohibited and may result in civil or criminal penalties. In
addition, you promise to abide by all copyright notices, trademark rules,
information, and restrictions contained in any User Output you access through
the Services in any way (including through the Website and Applications). </p>

<p class=BodyTextContinued>You hereby grant to EH2 a fully paid, worldwide,
perpetual, irrevocable, royalty-free license to use and incorporate into the
Services any suggestion, enhancement request, recommendation, correction or
other feedback provided by you relating to the Services.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Privacy; Electronic Communications</u></b></p>

<p class=BodyTextContinued>Your use of the Services and provision of Customer
Data is governed by our Privacy Policy (See https://eh2.app/lcoh+/privacy-policy)
which is incorporated by reference as if fully set forth herein. Your use of or
access to the Services constitutes your agreement to our collection, use and
disclosure of your Customer Data in accordance with the Privacy Policy. EH2
uses its best efforts to implement and maintain prudent, reasonable measures to
maintain security and confidentiality of Customer Data in accordance with these
Terms.</p>

<p class=BodyTextContinued>You consent to receive electronically any
communications related to your use of the Services. We may  communicate with
you by email or by posting notices on our websites. You agree that all
agreements, notices, disclosures and other communications that are provided to
you electronically satisfy any legal requirement that such communications be in
writing. All notices from us intended for receipt by you shall be deemed
delivered and effective when sent to the email address you provide to us.
Please note that by submitting Customer Data, creating a user account or
otherwise providing us with your email address, postal address or phone number,
you are agreeing that we or our agents may contact you at that address and
number in a manner consistent with our Privacy Policy.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Copyright Infringement</u></b></p>

<p class=BodyTextContinued>EH2 respects the intellectual property rights of
others and asks that the people who use the Services do the same. The Digital
Millennium Copyright Act of 1998 (the “<b><i>DMCA</i></b>”) provides recourse
for copyright owners who believe that material appearing on the Internet
infringes their rights under U.S. copyright law. If you believe in good faith
that materials available through the Services infringe your copyright, you (or
your agent) may send EH2 a notice requesting that we remove the material or
block access to it. If you believe in good faith that someone has wrongly filed
a notice of copyright infringement against you, the DMCA permits you to send EH2
a counter-notice. Notices and counter-notices must meet the then-current
statutory requirements imposed by the DMCA. See https://www.copyright.gov/ for
details. Notices and counter-notices should be sent to: <a
href="mailto:LCOH@eh2.com">LCOH@eh2.com</a>. We encourage you to consult your
legal advisor before filing a notice or counter-notice.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Third-Party Services</u></b></p>

<p class=BodyTextContinued>The Services may contain data, links or connections
to or from third party websites, products or services, including those of other
Users of the Services, that are not owned or controlled by EH2 (“<b><i>Third-Party
Services</i></b>”) or may enable transmission of communications, information or
data to or from third parties (“<b><i>Third-Party Communications</i></b>”).
When you access or use Third-Party Services (including any data or information
obtained through such Third-Party Services) or engage in Third Party
Communications, you accept that there are risks in doing so, and that EH2 is
not responsible for such risks. We also encourage you to read the terms and
conditions and privacy policy of all Third-Party Services that you visit or
utilize. EH2 has no control over, and assumes no responsibility for, the
information, accuracy, privacy policies, or practices of or opinions expressed
in any Third-Party Services or Third-Party Communications. In addition, EH2
will not and cannot monitor, verify, censor or edit any Third-Party
Communications or information of any Third-Party Services. By using the
Services, you release and hold us harmless from any and all liability arising
from your access to or use of any Third-Party Services or from engaging in any
Third-Party Communications.</p>

<p class=BodyTextContinued>Some portions of the Services implement features and
content provided by Google Maps Platform. Additionally, the Services utilize
Google Cloud Platform to store User account information. You acknowledge that, 
by using the Services, you are bound by Google Maps/Google Earth Additional
Terms of Service (currently accessible at <a
href="https://maps.google.com/help/terms_maps">https://maps.google.com/help/terms_maps</a>),
Google Cloud Platform Terms of Service (currently accessible at <a
href="https://cloud.google.com/terms">https://cloud.google.com/terms</a>) and
Google’s Privacy Policy (currently accessible at <a
href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>).
</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Third-Party Websites and Advertisements</u></b></p>

<p class=BodyTextContinued>The Services may contain links to third-party
websites and applications (each a “<b><i>Third-Party Property</i></b>”, and collectively,
“<b><i>Third-Party Properties</i></b>”). When you click on a link to a Third-Party
Property, we will not warn you that you have left the Website and are subject
to the terms of use (including privacy policies) of another website or
destination. We do not control or endorse those Third-Party Properties, nor do
we endorse any goods or services sold on those Third-Party Properties, except
where expressly stated to the contrary on our website or in other EH2 marketing
materials. Similarly, the Services may contain advertisements for products or
services of third parties. We do not control or endorse any such products or
services being advertised except where expressly stated to the contrary on our
website or in other EH2 marketing materials.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>11.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Fees</u></b></p>

<p class=BodyTextContinued>The Services, including the Website and the
Applications, are provided at no charge. We reserve the right to modify our fee
structure under these Terms and to introduce new charges applicable to the
Services at any time upon notice to you (which may be sent by email or posted
on a EH2 website). </p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>12.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Term; Termination</u></b></p>

<p class=BodyTextContinued><span style='font-size:10.5pt'>The Terms commence on
the date when you accept them (as described in the preamble above) and will
remain in full force and effect while you use the Services, unless terminated
earlier in accordance with the Agreement.</span></p>

<p class=BodyTextContinued>EH2 may, in its sole discretion, at any time for any
reason or no reason, terminate your access to the Services and any account(s)
you may have in connection with this Services, including if EH2 believes that
you have violated or acted inconsistently with the letter or spirit of these
Terms or if it is required by applicable law, rule or regulation.</p>

<p class=BodyTextContinued>Our termination of your access to the Services may
be affected without notice and, on such termination, we may immediately
deactivate or delete your account and/or bar any further access to such
Services or account, and your right to use the Services will immediately cease.
EH2 shall not be liable to you or any third party for any termination of your
access to the Services or account hereunder.</p>

<p class=BodyTextContinued><span style='font-size:10.5pt'>If you want to
terminate the Services provided, you may do so by (a) by selecting the option
to delete your account on the Website or (b) notifying us by e-mail at </span><a
href="mailto:LCOH@eh2.com">LCOH@eh2.com</a><span style='font-size:10.5pt'>.  </span></p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>13.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Modifications to Services</u></b></p>

<p class=BodyTextContinued>The Services may change over time. We may suspend or
discontinue all or any part of the Services, or we may introduce new features
or impose limits on certain features or restrict access to parts or all of the
Services. Similarly, we reserve the right to remove any Customer Data from the
Services at any time in the event that we determine in our discretion that you
have breached the Terms.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>14.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Disclaimer of Warranties</u></b></p>

<p class=BodyTextContinued><span style='text-transform:uppercase'>Your use of
the Services is at your own risk. The Services and any content, information,
products or services made available on or through the Services are provided on
an “as is” and “as available” basis without warranty of any kind except to the
extent expressly provided to the contrary by EH2 to you in writing. Except to
the extent of any express warranty made to you by EH2 in writing, EH2 and/or
its suppliers and licensors hereby disclaim all warranties, express or implied,
in relation to the Services or any content, information, products or services
made available on or through the Services, including but not limited to the
implied warranties of merchantability, fitness for a particular purpose and
noninfringement, to the maximum extent permissible under applicable law.
Without limitation to the foregoing disclaimers, EH2 and/or its suppliers and
licensors hereby disclaim all warranties, express or implied, in relation to
Third-Party Services or Third-Party Communications.</span></p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>15.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Limitation of Liability</u></b></p>

<p class=BodyTextContinued><span style='text-transform:uppercase'>IN no event
shall EH2 and/or its suppliers/licensors and its or their officers, directors,
employees, or agents be liable for any special, incidental or consequential or
punitive damages of any character, including damages for loss of goodwill, lost
profits, lost sale or business, work stoppage, computer failure or malfunction,
lost content or data, including by contract, equity, negligence or other
tortious action in connection with your use of the Services or the Terms,
however arising, even if advised in advance of such damages or losses. The
maximum liability of EH2 and its officers, directors, employees and agents to
you or any third party shall not exceed $1,000.</span> Some states do not allow
the exclusion or limitation of incidental or consequential damages, so to that
extent the above limitation or exclusion may not apply to you.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>16.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Indemnity</u></b></p>

<p class=BodyTextContinued>To the fullest extent permitted by law, you shall
indemnify, defend and hold harmless EH2, its licensors/suppliers and their
respective officers, directors, employees and agents from any and all claims,
demands, damages, costs and liabilities, including reasonable attorneys’ fees,
arising out of or in connection with: (1) any of your Customer Data, including
an assertion that the information, content, or other materials or services
provided or made available by you or the use thereof, may infringe any
copyright, trademark, or other intellectual property rights of any individual
or entity, or misappropriate any individual or entity's trade secret, or
contain any libelous, defamatory, disparaging, pornographic, or obscene
materials; (2) any breach by you of your obligations or covenants under these
Terms; (3) your unlawful and/or unauthorized use of, or activities in
connection with, the Services, including the Website and Applications. The
foregoing indemnities shall survive expiration or termination of these Terms.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>17.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Assignments</u></b></p>

<p class=BodyTextContinued>You may not assign, delegate or transfer these Terms
or your rights or obligations hereunder, or your Services account, in any way
(by operation of law or otherwise) without EH2’s prior written consent. We may
transfer, assign, or delegate these Terms and our rights and obligations
without consent.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>18.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>Governing Law; Dispute Resolution</u></b></p>

<p class=BodyTextContinued>These Terms are governed by and will be construed
under the laws of the State of California, without regard to the conflicts of
laws provisions thereof. Any dispute arising from or relating to the subject
matter of these Terms (whether brought against EH2 or against any director,
officer or employee of EH2 in their personal capacity) shall be finally settled
in San Mateo County, California, in English, in accordance with the Streamlined
Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services,
Inc. (“<b><i>JAMS</i></b>”) then in effect, by one commercial arbitrator with
substantial experience in resolving intellectual property and commercial
contract disputes, who shall be selected from the appropriate list of JAMS
arbitrators in accordance with such Rules. Judgment upon the award rendered by
such arbitrator may be entered in any court of competent jurisdiction.
Notwithstanding the foregoing obligation to arbitrate disputes, each party
shall have the right to pursue injunctive or other equitable relief at any
time, from any court of competent jurisdiction. For all purposes of these
Terms, the parties consent to exclusive jurisdiction and venue in the state or
federal courts located in, respectively, Alameda County, California, or the
Northern District of California. Any arbitration under these Terms will take
place on an individual basis: class arbitrations and class actions are not
permitted. You understand and agree that by entering into the Terms, you and EH2
are each waiving the right to trial by jury or to participate in a class
action.</p>

<p class=StandardL1 style='margin-left:0in;text-indent:0in'><b>19.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><u>General</u></b></p>

<p class=BodyTextContinued>The failure of either you or us to exercise, in any
way, any right herein shall not be deemed a waiver of any further rights
hereunder. If any provision of these Terms is found to be unenforceable or
invalid, that provision will be limited or eliminated, to the minimum extent
necessary, so that these Terms shall otherwise remain in full force and effect
and enforceable. Except where expressly agreed in writing otherwise, and except
for our Privacy Policy, you and EH2 agree that these Terms are the complete and
exclusive statement of the mutual understanding between you and EH2, and that
it supersedes and cancels all previous written and oral agreements,
communications and other understandings relating to the subject matter of these
Terms. You hereby acknowledge and agree that you are not an employee, agent,
partner, or joint venture of EH2, and you do not have any authority of any kind
to bind EH2 in any respect whatsoever. You and EH2 agree there are no third-party
beneficiaries intended under these Terms.</p>

<p class=BodyTextContinued>If you have any general question, comment or
complaint regarding the Services, please send an e-mail to <a
href="mailto:LCOH@eh2.com">LCOH@eh2.com</a>.  Formal notices to EH2 under these
Terms (including a report of any violation of the Terms by any user) shall be
sufficient only if in writing and transmitted via personal delivery or
delivered by a major commercial rapid delivery courier service or by certified
or registered mail, return receipt requested, to: 835 East San Carlos Avenue,
Suite 100, San Carlos, California 94070, USA, with a copy to <a
href="mailto:LCOH@eh2.com"><span style='color:windowtext;text-decoration:none'> </span>LCOH
@eh2.com</a>. Notices to you may be made via posting by us to our websites, by
e-mail, or by regular mail, in EH2’s discretion. Without limitation, you agree
that a printed version of these Terms and of any notice given in electronic
form shall be admissible in judicial or administrative proceedings based upon
or relating to these Terms to the same extent and subject to the same conditions
as other business documents and records originally generated and maintained in
printed form.</p>

</div>

</body>

</html>
    `;
}
