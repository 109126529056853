import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useLabeledBoxStyles, TitledBoxAlignment } from './titled-box.styles';

export interface TitledBoxProps {
  children: ReactNode;
  title: ReactNode;
  align: TitledBoxAlignment;
  classes?: TitledBoxClasses;
  fullHeight?: boolean;
}

export interface TitledBoxClasses {
  root?: string;
  box?: string;
}

export function TitledBox(props: TitledBoxProps) {
  const classes = useLabeledBoxStyles({ 
    align: props.align,
    fullHeight: props.fullHeight,
  });

  return (
    <div className={clsx(classes.root, props.classes?.root)}>
      <div className={classes.titleOuter}>
        <div className={classes.titleInner}>
          {props.title}
        </div>
      </div>

      <div className={clsx(classes.box, props.classes?.box)}>
        {props.children}
      </div>
    </div>
  );
}
