import { getApps, getApp, initializeApp } from 'firebase/app';
import { getAuth, User, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, updatePassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail as fbSendPasswordResetEmail, confirmPasswordReset, updatePassword as fbUpdatePassword, createUserWithEmailAndPassword } from 'firebase/auth';

const apiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
if (!apiKey) {
  throw new Error('missing env var NEXT_PUBLIC_FIREBASE_API_KEY');
}

const authDomain = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
if (!authDomain) {
  throw new Error('missing env var NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN');
}

const firebaseApp = getApps().length ? getApp() : initializeApp({ apiKey, authDomain });

export const firebaseAuth = getAuth(firebaseApp);

export function register(email: string, password: string) {
  return createUserWithEmailAndPassword(firebaseAuth, email, password);
}

export function initializeRegistration(email: string, completionUrl: string) {
  localStorage.setItem('registration_email', email);
  return sendSignInLinkToEmail(firebaseAuth, email, {
    url: completionUrl,
    handleCodeInApp: true
  });
}

export function getRegistrationEmailAddress() {
  return localStorage.getItem('registration_email');
}

export async function completeRegistration(email:string, password: string) {
  if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
    const result = await signInWithEmailLink(firebaseAuth, email, window.location.href);
    await updatePassword(result.user, password);
    return localStorage.removeItem('registration_email');
  }
}

export function login(email: string, password: string) {
  return signInWithEmailAndPassword(firebaseAuth, email, password);
}

export function logout() {
  return signOut(firebaseAuth);
}

export function sendResetPasswordEmail(email: string, completionUrl: string) {
  return fbSendPasswordResetEmail(firebaseAuth, email, {
    url: completionUrl,
    handleCodeInApp: true
  });
}

export function resetPassword(oobCode: string, newPassword: string) {
  return confirmPasswordReset(firebaseAuth, oobCode, newPassword);
}

export async function changePassword(newPassword: string, oldPassword: string) {
  const user: User|null = firebaseAuth.currentUser;
  if (!user) {
    throw new Error('cannot change password email because user in unauthenticated');
  }
  if (!user.email) {
    throw new Error('cannot change password email because user has no email address');
  }

  // todo: this is not 100% secure
  await login(user.email, oldPassword); // if the oldPassword is wrong, this will throw

  await fbUpdatePassword(user, newPassword);
}

export async function getFirebaseToken() {
  return firebaseAuth.currentUser?.getIdToken();
}

export interface RegistrationError {
  code: string;
}

export enum RegistrationErrorCode {
  WeakPassword = 'auth/weak-password',
  EmailTaken = 'auth/email-already-in-use'
}
