import { makeStyles } from '@material-ui/core/styles';

export const useChassisV2Styles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  toolbarDropdown: {
    display: 'flex',
    alignItems: 'center'
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}));
