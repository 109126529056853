import React, { useState, useCallback } from 'react';
import { TextField, Button, Typography } from '@material-ui/core';
import { useAsync } from 'react-async';

import { useSharedStyles } from './shared.styles';

export interface CompleteRegistrationFormProps {
  logoUrl: string;
  email: string;
  submit: (password: string) => Promise<unknown>;
  onSubmitSuccess: () => void;
}

export function CompleteRegistrationForm({ submit, onSubmitSuccess, ...props }: CompleteRegistrationFormProps) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  const deferFn = useCallback(() => submit(password), [password, submit]);
  const handleResolve = useCallback(() => {
    setIsSuccessful(true);
    onSubmitSuccess();
  }, [onSubmitSuccess]);
  const { run: login, isPending, isRejected } = useAsync({
    deferFn,
    onResolve: handleResolve
  });

  const canSubmit = password && password === confirmPassword && !isPending && !isSuccessful;

  const classes = useSharedStyles();

  return (
    <div className={classes.root}>
      <img
        src={props.logoUrl}
        alt="Electric Hydrogen Logo"
        className={classes.logo}
      />

      <TextField
        autoFocus
        label="Email"
        disabled
        value={props.email}
      />

      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />

      <TextField
        label="ConfirmPassword"
        type="password"
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
      />

      <Button
        onClick={login}
        disabled={!canSubmit}
        color="primary"
      >Complete Registration</Button>

      {isRejected && <Typography color="primary">An error occurred</Typography>}
    </div>
  )
}
