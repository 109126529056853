import { makeStyles } from '@material-ui/core';

export const useEmptyStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.hint,
    },
    icon: {
      '&.MuiSvgIcon-root': { // extra specificity weight
        marginBottom: theme.spacing(5),
      }
    },
    text: {
      lineHeight: 1,
    },
  };
});
