import React from 'react';
import { Typography } from '@material-ui/core';

import { InfoTooltip } from '~/common/components';

import { useAuthFormStyles } from './auth-form.styles';

export function DataPrivacyNotice() {
  const classes = useAuthFormStyles();
  return (
    <div className={classes.dataPrivacyContent}>
      <div className={classes.dataPrivacyHeadingContainer}>
        <DataPrivacyHeading />
      </div>
      
      <Typography className={classes.dataPrivacyMainText}>
        <span>
          Users will be prompted to read and accept our Terms of Uses.
        </span>
        <span>
          Please read and note that Electric Hydrogen cares about and protects your data and your privacy. Your data is treated as confidential.
        </span>
        <span>
          From EH2 Analytics Platform’s Terms of Uses:
        </span>
        <span className={classes.dataPrivacyExcerptText}>
          "Data will be treated by EH2 as confidential, except to the extent that you, or a representative from your organization having appropriate administrative authority, authorize us to disclose to other users"
        </span>
      </Typography>
      
      
    </div>
  )
}

export interface DataPrivacyHeadingProps {
  showTooltip?: boolean;
}

export function DataPrivacyHeading(props: DataPrivacyHeadingProps) {
  const classes = useAuthFormStyles();
  return (
    <div className={classes.dataPrivacy}>
      <img
        src="/images/data-privacy.png"
        className={classes.dataPrivacyIcon}
      />

      <Typography style={{ fontWeight: 'bold' }}>
        EH2 protects your data

        {props.showTooltip && (
          <InfoTooltip
            classes={{
              tooltip: classes.dataPrivacyTooltip,
            }}
            placement="right-end"
            style={{ marginLeft: 8 }}
            title={
              <div>
                <DataPrivacyNotice />
              </div>
            }
          />
        )}
      </Typography>
    </div>
  )
}
