import React, { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';

import { logout, useAuth } from '~/app/auth';
import { AuthFormContainer } from '~/app';
import { ChassisV2, PagePending, TosDialog, Logo, SidebarActiveItem } from '~/common/components';

import { useNextgenIntegrationContext } from '../../hooks/nextgen-integration';
import { useTos } from './use-tos';
import { useEntitlement } from './use-entitlements';
import { ChassisTitleView } from './chassis-title.view';

export interface ChassisV2ContainerProps {
  children: ReactNode;
  unsecure?: boolean;
  title: ReactNode;
  paths?: {
    about?: string;
    privacyPolicy?: string;
    changeLog?: string;
  }
  showApi?: boolean;
  sidebarActiveItem?: SidebarActiveItem;
  showLcohplusTutorials?: boolean
}

export function ChassisV2Container(props: ChassisV2ContainerProps) {
  const auth = useAuth();
  const { isNextgenIntegrated } = useNextgenIntegrationContext();

  if (!props.unsecure) {
    if (!auth.attempted) {
      return <PagePending />;
    }
    if (!auth.firebaseUser) {
      if (isNextgenIntegrated) {
        window.location.assign('/auth');
        return <PagePending />;
      }
      return <AuthFormContainer />;
    }
  }

  return (
    <ChassisV2ContainerInner
      {...props}
      userEmailAddress={auth.firebaseUser?.email || undefined}
    >
      {props.children}
    </ChassisV2ContainerInner>
  );
}

export type ChassisV2ContainerInnerProps = ChassisV2ContainerProps & {
  isEh2User?: boolean;
  userEmailAddress?: string;
}

function ChassisV2ContainerInner({ unsecure = true, ...props }: ChassisV2ContainerInnerProps) {
  const router = useRouter();
  const tos = useTos();
  const { lcohplusPermissionName, isNextgenIntegrated } = useNextgenIntegrationContext();

  const goToChangePassword = () => {
    return router.push('/change-password');
  };

  useEffect(() => {
    tos.checkTosAcceptance();
  }, []);

  const [canUseInternalFeatures] = useEntitlement('eh2InternalFeatures');
  const [hasLcohplusPremium] = useEntitlement('lcohplusPremium');
  const [hasDlcoh] = useEntitlement(lcohplusPermissionName);

  if (!tos.isCheckResolved) {
    return <PagePending />
  }

  const tosDialog = (
    <TosDialog
      accept={tos.acceptTos}
      acceptancePending={tos.isAcceptancePending}
    />
  );

  return (
    <ChassisV2
      title={<ChassisTitleView title={props.title} />}
      tos={tosDialog}
      showTos={!tos.isTosAccepted}
      showApi={!!props.showApi}
      topMenuProps={{
        aboutPath: props.paths?.about,
        privacyPolicyPath: props.paths?.privacyPolicy || '/privacy-policy',
        changePassword: goToChangePassword,
        logout,
        emailAddress: props.userEmailAddress,
        changeLogPath: props.paths?.changeLog,
        hasApiAccess: hasLcohplusPremium,
      }}
      isDlcohLite={!hasDlcoh}
      sidebarProps={{
        logo: <Logo />,
        showEh2InternalFeatures: canUseInternalFeatures,
        clickHandlers: {
          slcoh: () => router.push('/lcoh/model'),
          dlcoh: () => router.push('/lcoh+'),
          pce: () => router.push('/pce'),
          offtaker: () => router.push('/hydrogen-offtaker'),
          gepb: () => router.push('/global-electrolysis'),
          electrolysisManufacturingDashboard: () => router.push('/electrolysis-manufacturing'),
          userAnalytics: () => router.push('/user-analytics'),
        },
        paths: {
          whitepapers: '/whitepapers'
        },
        mailTo: 'info@eh2.com',
        activeItem: props.sidebarActiveItem,
        showH2Predict: false, //isNextgenIntegrated,
      }}
      showLcohplusTutorials={props.showLcohplusTutorials}
    >
      <div>
        {props.children}
      </div>
    </ChassisV2>
  )
}
