import * as yup from 'yup';

export function checkValidEmail(value: string) {
  try {
    yup.string().email().validateSync(value);
    return true;
  } catch (error) {
    return false;
  }
}
