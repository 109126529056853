import React, { useEffect, HTMLAttributes } from 'react';
import { useInView } from 'react-intersection-observer';

import { TosContent } from './tos-content';
import { PrivacyPolicyContent } from './privacy-policy-content';

export interface TosProps {
  height: number;
  onRead: () => void;
  wrapperProps?: Partial<HTMLAttributes<HTMLDivElement>>;
}

export function Tos(props: TosProps) {
  const { onRead } = props;

  const [ref, hasRead] = useInView({ triggerOnce: true });
  useEffect(() => {
    if (hasRead) {
      onRead();
    }
  }, [hasRead, onRead])

  return (
    <div
      {...props.wrapperProps}
      style={{
        ...props.wrapperProps?.style,
        height: props.height,
        overflowY: 'scroll'
      }}
    >
      <TosContent />

      <PrivacyPolicyContent />

      <div style={{ height: 10 }} ref={ref}/>
    </div>
  )
}
