import { useState, useCallback } from 'react';

export interface SnackbarControllerProps {
  duration?: number;
}

export const DEFAULT_SNACKBAR_DURATION = 4000;

export type UseSnackbarControllerResult<T> = [T|undefined, (data: T, opts?: SnackbarControllerProps) => void]

export function useSnackbarController<T>(props: SnackbarControllerProps = {}): UseSnackbarControllerResult<T> {
  const { duration = DEFAULT_SNACKBAR_DURATION } = props;

  const [snackbarState, setSnackbarState] = useState<T|undefined>(undefined);

  const displaySnackbar = useCallback((data: T, opts?: SnackbarControllerProps) => {
    setSnackbarState(data);
    setTimeout(() => setSnackbarState(undefined), opts?.duration || duration);
  }, [duration])

  return [snackbarState, displaySnackbar];
}
