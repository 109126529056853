import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControlLabel, Checkbox } from '@material-ui/core';

import { getPendingButtonProps } from '../button';

import { Tos } from './tos';

export interface TosDialogProps {
  accept: () => void;
  acceptancePending: boolean;
}

export function TosDialog(props: TosDialogProps) {
  const [hasRead, setHasRead] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);

  return (
    <Dialog open={true}>
      <DialogTitle>Terms of Service & Privacy Policy</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Tos
          height={400}
          onRead={() => setHasRead(true)}
          wrapperProps={{
            style: {
              padding: 24,
              borderTop: 'solid 1px #ccc',
              borderBottom: 'solid 1px #ccc'
            }
          }}
        />
      </DialogContent>
      <DialogActions style={{ padding: 24 }}>
        <FormControlLabel
          disabled={!hasRead}
          control={
            <Checkbox
              color="primary"
              value={hasAgreed}
              onChange={(e, val) => setHasAgreed(val)}
            />
          }
          label="I have read agree to the Terms and Conditions and Privacy Policy"
        />

        <Button
          color="primary"
          onClick={props.accept}
          {...getPendingButtonProps({
            pending: props.acceptancePending,
            invalid: !hasRead || !hasAgreed
          })}
          style={{ marginLeft: 32  }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
