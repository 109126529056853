import React from 'react';

export function PrivacyPolicyContent() {
  return <div dangerouslySetInnerHTML={{ __html: privacyPolicy() }}/>;
}

function privacyPolicy() {
  return `
<html xmlns:ns0="urn-legalmacpac-data/10">

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
\t{font-family:Wingdings;
\tpanose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
\t{font-family:"MS Mincho";
\tpanose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
\t{font-family:SimSun;
\tpanose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
\t{font-family:"Cambria Math";
\tpanose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
\t{font-family:Calibri;
\tpanose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
\t{font-family:"Noto Sans Symbols";}
@font-face
\t{font-family:Avenir;}
@font-face
\t{font-family:Georgia;
\tpanose-1:2 4 5 2 5 4 5 2 3 3;}
@font-face
\t{font-family:"Segoe UI";
\tpanose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
\t{font-family:Cambria;
\tpanose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
\t{font-family:"Open Sans";}
@font-face
\t{font-family:"\\@SimSun";
\tpanose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
\t{font-family:"\\@MS Mincho";
\tpanose-1:2 2 6 9 4 2 5 8 3 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
\t{margin:0in;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
h1
\t{mso-style-link:"Heading 1 Char";
\tmargin-top:5.0pt;
\tmargin-right:0in;
\tmargin-bottom:5.0pt;
\tmargin-left:0in;
\tfont-size:24.0pt;
\tfont-family:"Times New Roman",serif;
\tfont-weight:bold;}
h2
\t{mso-style-link:"Heading 2 Char";
\tmargin-top:5.0pt;
\tmargin-right:0in;
\tmargin-bottom:5.0pt;
\tmargin-left:0in;
\tfont-size:18.0pt;
\tfont-family:"Times New Roman",serif;
\tfont-weight:bold;}
p.MsoToc1, li.MsoToc1, div.MsoToc1
\t{margin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:5.0pt;
\tmargin-left:.5in;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;
\tcolor:black;
\tfont-weight:bold;}
.WordSection1 a:link, span.MsoHyperlink
\t{color:blue;
\ttext-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
\t{mso-style-link:"List Paragraph Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:0in;
\tmargin-left:.5in;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
\t{mso-style-link:"List Paragraph Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:0in;
\tmargin-left:.5in;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
\t{mso-style-link:"List Paragraph Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:0in;
\tmargin-left:.5in;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
\t{mso-style-link:"List Paragraph Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:0in;
\tmargin-left:.5in;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
span.Heading2Char
\t{mso-style-name:"Heading 2 Char";
\tmso-style-link:"Heading 2";
\tfont-family:"Times New Roman",serif;
\tfont-weight:bold;}
span.Heading1Char
\t{mso-style-name:"Heading 1 Char";
\tmso-style-link:"Heading 1";
\tfont-family:"Times New Roman",serif;
\tfont-weight:bold;}
span.ListParagraphChar
\t{mso-style-name:"List Paragraph Char";
\tmso-style-link:"List Paragraph";}
p.UKBasicL1, li.UKBasicL1, div.UKBasicL1
\t{mso-style-name:UKBasic_L1;
\tmso-style-link:"UKBasic_L1 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:.5in;
\ttext-align:justify;
\ttext-indent:0in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;
\tcolor:black;}
span.UKBasicL1Char
\t{mso-style-name:"UKBasic_L1 Char";
\tmso-style-link:UKBasic_L1;
\tfont-family:"Arial",sans-serif;
\tcolor:black;}
p.UKBasicL2, li.UKBasicL2, div.UKBasicL2
\t{mso-style-name:UKBasic_L2;
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:1.0in;
\ttext-align:justify;
\ttext-indent:-.25in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;
\tcolor:black;}
p.UKBasicL3, li.UKBasicL3, div.UKBasicL3
\t{mso-style-name:UKBasic_L3;
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:110.5pt;
\ttext-align:justify;
\ttext-indent:-.25in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;
\tcolor:black;}
p.UKBasicL4, li.UKBasicL4, div.UKBasicL4
\t{mso-style-name:UKBasic_L4;
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:146.5pt;
\ttext-align:justify;
\ttext-indent:-.25in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;
\tcolor:black;}
p.UKBasicL5, li.UKBasicL5, div.UKBasicL5
\t{mso-style-name:UKBasic_L5;
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:182.5pt;
\ttext-align:justify;
\ttext-indent:-.25in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;
\tcolor:black;}
p.UKHeading1L1, li.UKHeading1L1, div.UKHeading1L1
\t{mso-style-name:UKHeading1_L1;
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:.5in;
\ttext-align:justify;
\ttext-indent:-.5in;
\tline-height:125%;
\tpage-break-after:avoid;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;
\tfont-weight:bold;}
p.UKHeading1L2, li.UKHeading1L2, div.UKHeading1L2
\t{mso-style-name:UKHeading1_L2;
\tmso-style-link:"UKHeading1_L2 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:.5in;
\ttext-align:justify;
\ttext-indent:-.5in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;}
p.UKHeading1L3, li.UKHeading1L3, div.UKHeading1L3
\t{mso-style-name:UKHeading1_L3;
\tmso-style-link:"UKHeading1_L3 Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:1.0in;
\ttext-align:justify;
\ttext-indent:-.5in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;}
p.UKHeading1L4, li.UKHeading1L4, div.UKHeading1L4
\t{mso-style-name:UKHeading1_L4;
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:2.0in;
\ttext-align:justify;
\ttext-indent:-.25in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;}
p.UKHeading1L5, li.UKHeading1L5, div.UKHeading1L5
\t{mso-style-name:UKHeading1_L5;
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:2.5in;
\ttext-align:justify;
\ttext-indent:-.25in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;}
p.UKHeading1L6, li.UKHeading1L6, div.UKHeading1L6
\t{mso-style-name:UKHeading1_L6;
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:12.0pt;
\tmargin-left:3.0in;
\ttext-align:justify;
\ttext-indent:-.25in;
\tline-height:125%;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;}
span.UKHeading1L2Char
\t{mso-style-name:"UKHeading1_L2 Char";
\tmso-style-link:UKHeading1_L2;
\tfont-family:"Arial",sans-serif;}
span.UKHeading1L3Char
\t{mso-style-name:"UKHeading1_L3 Char";
\tmso-style-link:UKHeading1_L3;
\tfont-family:"Arial",sans-serif;}
p.Bullet1, li.Bullet1, div.Bullet1
\t{mso-style-name:Bullet1;
\tmargin-top:6.0pt;
\tmargin-right:0in;
\tmargin-bottom:6.0pt;
\tmargin-left:.5in;
\ttext-align:justify;
\ttext-indent:-.25in;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;}
p.Bullet2, li.Bullet2, div.Bullet2
\t{mso-style-name:Bullet2;
\tmargin-top:6.0pt;
\tmargin-right:0in;
\tmargin-bottom:6.0pt;
\tmargin-left:.5in;
\ttext-align:justify;
\ttext-indent:-.25in;
\tfont-size:10.0pt;
\tfont-family:"Arial",sans-serif;}
.MsoChpDefault
\t{font-size:10.0pt;
\tfont-family:"Calibri",sans-serif;}
 /* Page Definitions */
 @page WordSection1
\t{size:8.5in 11.0in;
\tmargin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
\t{page:WordSection1;}
 /* List Definitions */
 ol
\t{margin-bottom:0in;}
ul
\t{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'><a
name="_Toc1737935"><b><span style='font-size:16.0pt'>Privacy Policy</span></b></a></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'><span
style='color:black'>Effective as of July 22, 2022  </span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'>This Privacy
Policy describes the privacy practices of Electric Hydrogen Co.<span
style='color:black'> </span>(&quot;<b>EH2</b>,&quot; &quot;<b>we</b>&quot;, “<b>us</b>”
or &quot;<b>our</b>&quot;) and how we handle personal information that we
collect through our digital properties that link to this Privacy Policy,
including our website and web application (collectively, the “<b>Service</b>”),
as well as through social media, our marketing activities, and other activities
described in this Privacy Policy.  </p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'>EH2 provides online
analytics tools that calculate the cost of producing hydrogen from renewable
electricity sources. This Privacy Policy does not apply to information that we
process on behalf of our business customers while providing the EH2 platform to
them. Our use of this information is restricted by our agreements with those
business customers. </p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'>Our websites, products and services are
designed for businesses and their representatives. We do not offer products or
services for use by individuals for their personal, family or household
purposes. Accordingly, we treat all personal information we collect as
pertaining to individuals in their capacities as business representatives and
not their individual capacities.</p>

<p class=MsoNormal style='margin-top:6.0pt;text-align:justify;line-height:115%;
border:none'>If you are located in the European Economic Area (“EEA”) or the
United Kingdom (“UK”), please consult the <a href="#_Notice_to_European">EEA/UK
GDPR supplemental notice</a> below. </p>

<p class=MsoNormal style='margin-top:6.0pt;text-align:justify;line-height:115%;
border:none'><b><span style='font-size:16.0pt;line-height:115%'>Index</span></b></p>

<p class=MsoToc1 style='text-align:justify'><a
href="#_Personal_information_we">Personal information we collect</a></p>

<p class=MsoToc1 style='text-align:justify'><a href="#_Toc24326411"><span
style='color:black;text-decoration:none'>How we use your personal information</span></a></p>

<p class=MsoToc1 style='text-align:justify'><a href="#_Toc24326424"><span
style='color:black;text-decoration:none'>How we share your personal information</span></a></p>

<p class=MsoToc1 style='text-align:justify'><a href="#_Toc24326425"><span
style='color:black;text-decoration:none'>Your choices</span></a></p>

<p class=MsoToc1 style='text-align:justify'><a href="#_Toc24326434"><span
style='color:black;text-decoration:none'>Other sites and services</span></a></p>

<p class=MsoToc1 style='text-align:justify'><span class=MsoHyperlink><span
style='color:black;text-decoration:none'>How long do we keep your personal
information </span></span></p>

<p class=MsoToc1 style='text-align:justify'><a href="#_Toc24326436"><span
style='color:black;text-decoration:none'>Security</span></a></p>

<p class=MsoToc1 style='text-align:justify'><a href="#_Toc24326438"><span
style='color:black;text-decoration:none'>International data transfer</span></a></p>

<p class=MsoToc1 style='text-align:justify'><a href="#_Toc24326440"><span
style='color:black;text-decoration:none'>Children</span></a></p>

<p class=MsoToc1 style='text-align:justify'><a href="#_Toc24326442"><span
style='color:black;text-decoration:none'>Changes to this Privacy Policy</span></a></p>

<p class=MsoToc1 style='text-align:justify'><a href="#_Toc24326444"><span
style='color:black;text-decoration:none'>How to contact us</span></a></p>

<p class=MsoToc1 style='margin-bottom:6.0pt;text-align:justify'>EEA/UK GDPR Supplemental
Notice <a href="#_Toc24326474"><span style='color:black;text-decoration:none'> </span></a></p>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Personal_information_we"></a><a
name="personal_inforamtion_we_collect"></a><a name="_Toc24212344"></a><a
name="_Toc24326406"></a><a name="_Ref27929894"></a><a name="_Toc1737936"><span
style='font-size:16.0pt;font-family:"Calibri",sans-serif;color:black'>Personal information
we </span></a><span style='font-size:16.0pt;font-family:"Calibri",sans-serif;
color:black'>collect</span></h1>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc529964439"></a><a name="_Toc531681057"></a><a
name="_Toc1737937"></a><a name="_Toc24212345"></a><a name="_Toc24326407"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>Information
you provide to us.  </span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>Personal information you may provide to us
through the Service or otherwise includes:</span></h2>

<p class=MsoListParagraph style='text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Contact data</b>, such as your first and last name, email
address, billing and mailing addresses, professional title and company name, and
phone number.</p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='text-align:justify'><b>Profile data</b>,<b> </b>such
     as the username and password that you may set to establish an online
     account with us, company name and any other information that you add to
     your account profile.  </li>
 <li class=MsoNormal style='text-align:justify'><b>Communications</b> that we
     exchange with you, including when you contact us with questions or feedback,
     through the Service, social media, or otherwise. </li>
 <li class=MsoNormal style='text-align:justify'><b>Marketing data</b>, such as your
     preferences for receiving our marketing communications and details about your
     engagement with them.</li>
</ul>

<p class=MsoListParagraph style='margin-bottom:6.0pt;text-align:justify;
text-indent:-.25in'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Other data </b>not specifically listed here, which we will use
as described in this Privacy Policy or as otherwise disclosed at the time of
collection.</p>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc529964440"></a><a name="_Toc531681058"></a><a
name="_Toc1737938"></a><a name="_Toc24212346"></a><a name="_Toc24326408"></a><a
name="_1fob9te"></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>Third-party sources. </span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;color:black;font-weight:normal'>We may combine
personal information we receive from you with personal information we obtain
from other sources, such as:</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;
text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:Symbol;color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>Public sources</span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;color:black;font-weight:normal'>, such as government
agencies, public records, social media platforms</span><span style='font-size:
11.0pt;font-family:"Calibri",sans-serif;color:black;font-weight:normal'>, and
other publicly available sources.</span></h2>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='text-align:justify'><b>Data providers</b>, such as
     information services and data licensors that provide demographic and other
     information. </li>
 <li class=MsoNormal style='text-align:justify'><b>Our affiliate partners</b>,
     such as our affiliate network provider and publishers, influencers, and
     promoters who participate in our paid affiliate programs.</li>
</ul>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
.5in;text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:Symbol;color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>Marketing partners</span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;color:black;font-weight:normal'>,</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'> </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>such as joint marketing partners and event co-sponsors.</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212347"></a><a name="_Toc24326409"></a><a
name="_Toc1737939"><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>Automatic data collection</span></a><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;color:black'>.  </span><a name="_Toc1737940"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>We, our service providers, and our business partners may
automatically log information about you, your computer or mobile device, and your
interaction over time with the Service, our communications and other online
services, such as:</span></a></h2>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:6.0pt;text-align:justify;
text-indent:-.25in'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='color:black'>Device data</span></b><span
style='color:black'>,</span><b><span style='color:black'> </span></b>such as
your <a name="_cp_text_1_57">computer’s </a>or mobile <a name="_cp_text_1_59">device’s
</a>operating system type and version, manufacturer and model, browser type,
screen resolution, <a name="_cp_text_1_61">RAM and disk size, CPU usage, device
type (e.g., phone, tablet), </a>IP address, unique identifiers <a
name="_cp_text_1_63">(including identifiers used for advertising purposes),
language settings, mobile device carrier, radio/network information (e.g., Wi-Fi,
LTE, 3G)</a>, and general location information such as city, state or
geographic area.</p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:6.0pt;text-align:justify;
text-indent:-.25in'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Online activity data</b>, such as pages or screens you viewed,
how long you spent on a page or screen, <a name="_cp_text_1_64">the website you
visited before browsing to the Service, </a>navigation paths between pages or
screens, information about your activity on a page or screen, access times and
duration of access<a name="_cp_change_44"><span style='color:black'>, and
whether you have opened our marketing emails or clicked links within them</span></a>.</p>

<p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Location data </b>when you authorize the Service to access
your device’s location. </p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:6.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><a name="_Toc24212348"></a><a
name="_Toc24326410"><b><span style='color:black'>Cookies and similar
technologies</span></b></a><span style='color:black'>. Like many online
services, we use the following technologies:</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:6.0pt;text-align:justify;
text-indent:-.25in'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Cookies</b>, which are text files that websites store on a
visitor‘s device to uniquely identify the visitor’s browser or to store
information or settings in the browser for the purpose of helping you navigate
between pages efficiently, remembering your preferences<a name="_cp_text_1_99">,
enabling functionality, helping us understand user activity and patterns, and
facilitating analytics and online advertising</a>.</p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:6.0pt;text-align:justify;
text-indent:-.25in'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Local storage technologies</b>, like HTML5 and Flash, that
provide cookie-equivalent functionality but can store larger amounts of data,
including on your device outside of your browser in connection with specific
applications. </p>

<p class=MsoListParagraphCxSpLast style='margin-bottom:6.0pt;text-align:justify'><b>Web
beacons</b>, also known as pixel tags or clear GIFs, which are used to
demonstrate that a webpage or email address was accessed or opened, or that
certain content was viewed or clicked. </p>

<h1 style='text-align:justify'><a name="_Toc24326411"></a><a name="_Toc1737944"></a><a
name="_How_we_use"></a><span style='font-size:16.0pt;font-family:"Calibri",sans-serif'>How
we use your personal </span><span style='font-size:16.0pt;font-family:"Calibri",sans-serif'>information</span></h1>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'><a
name="_Hlk106206948"></a><a name="_2et92p0"></a>We may use your personal
information for the following purposes or as otherwise described at the time of
collection:</p>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc529964442"></a><a name="_Toc531681061"></a><a
name="_Toc1737945"></a><a name="_Toc24212349"></a><a name="_Toc24326412"></a><a
name=Servicedelivery></a><a name="_tyjcwt"></a><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>Service delivery</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.  </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>We may use your personal information to:</span></h2>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='color:black;text-align:justify;border:none'>provide,
     operate and improve the Service and our business;</li>
 <li class=MsoNormal style='color:black;text-align:justify;border:none'>establish
     and maintain your user profile on the Service;</li>
 <li class=MsoNormal style='color:black;text-align:justify;border:none'>enable
     security features of the Service, such as by sending you security codes
     via email or SMS, and remembering devices from which you have previously
     logged in;</li>
 <li class=MsoNormal style='color:black;text-align:justify;border:none'>communicate
     with you about the Service, including by sending announcements, updates,
     security alerts, and support and administrative messages;</li>
 <li class=MsoNormal style='color:black;text-align:justify;border:none'>understand
     your needs and interests, and personalize your experience with the Service
     and our communications; and</li>
 <li class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;border:none'><span
     style='color:black'>provide support for the Service, and respond to your
     requests, questions and feedback.</span><a name="_Toc529964445"></a><a
     name="_Toc531681063"></a><a name="_Toc1737946"></a><a name="_3dy6vkm"></a></li>
</ul>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212350"></a><a name="_Toc24326413"></a><a
name=Researchanddevelopment><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Research
and development</span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>. 
</span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>We may use your personal information for research and
development purposes, including to analyze and improve the Service and our
business.  </span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>As part of these activities, we may create aggregated,
de-identified or other anonymous data from personal information we collect.  We
make personal information into anonymous data by removing information that
makes the data personally identifiable to you.  We may use this anonymous data
and share it with third parties for our lawful business purposes, including to
analyze and improve the Service and promote our business.</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212351"></a><a name="_Toc1737947"></a><a
name="_Toc529964446"></a><a name="_Toc531681064"></a><a name="_Toc24326414"></a><a
name=Marketingandadvertising></a><a name="_1t3h5sf"></a><span style='font-size:
11.0pt;font-family:"Calibri",sans-serif'>Marketing and advertising</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.  </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>We,
our service providers and our third-party advertising partners may collect and
use your personal information for marketing and advertising purposes:</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;
text-align:justify;text-indent:-.25in'><a name="_Toc24326415"><span
style='font-size:11.0pt;font-family:Symbol;color:black;font-weight:normal'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Direct marketing</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>.
We may send you EH2-related or other direct marketing communications as
permitted by law. </span><a name="_To_comply_with"></a><span style='font-size:
11.0pt;font-family:"Calibri",sans-serif;color:black'> </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>You
may opt-out of our marketing communications<span style='color:black'> as
described in the </span></span><a href="#Opt_out_of_marketing"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>Opt-out
of marketing</span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'> section below.</span><span style='font-size:
11.0pt;font-family:"Calibri",sans-serif;color:black'>  </span></h2>

<p class=MsoNormal><span style='font-size:6.0pt'>&nbsp;</span></p>

<h2 style='margin-top:0in;text-align:justify'><a name="_Toc24326416"></a><a
name="_Interest-based_advertising._"></a><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;color:black'>Interest-based advertising.  </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>We
may engage third-party advertising companies and social media companies to
display ads on our Service and other <a name="_cp_text_1_78">online services. </a>These
companies may use cookies and similar <span style='color:black'>technologies to
collect information about your interaction (including the data described <a
name="_cp_change_62">in the </a><a name="_cp_change_63">automatic data
collection </a><a name="_cp_change_64">section</a> above) over time across the Service,
our communications and other <a name="_cp_text_1_80">online </a>services, and
use that information to serve online ads that they think will interest you. </span></span><a
name="_cp_text_1_211"><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>This is called interest-based advertising. We
may also share information about our users with these companies to facilitate
interest-based advertising to those or similar users on other online platforms.</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>  </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>You can learn more about your choices for limiting
interest-based advertising in the </span><a href="#_Your_choices"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>Your
Choices</span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'> section below. </span><a
name="To_comply_with_law"></a><a name="_Toc1737951"></a><a name="_Toc529964448"></a><a
name="_Toc531681066"></a><a name="_Toc20672905"></a><a name="_Toc20672986"></a><a
name="_Toc24212352"></a><a name="_Toc24326417"></a><a
name=Complianceandprotection></a><a name="_cp_blt_2_28"></a><a name="_4d34og8"></a><a
name="_2s8eyo1"></a><a name="_For_compliance,_fraud"></a><a
name="_Compliance_and_operations."></a><a name="_Compliance_and_protection."></a></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Compliance
and protection</span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>. 
</span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>We may use your personal information to:</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
38.15pt;text-align:justify;text-indent:-.25in'><a name="_Toc24212354"></a><a
name="_Toc24326419"><span style='font-size:11.0pt;font-family:Symbol;
color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>comply with applicable laws, lawful requests,
and legal process, such as to respond to subpoenas or requests from government
authorities;</span></a></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
38.15pt;text-align:justify;text-indent:-.25in'><a name="_Toc24212355"></a><a
name="_Toc24326420"><span style='font-size:11.0pt;font-family:Symbol;
color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>protect our, your or others’ rights, privacy,
safety or property (including by making and defending legal claims);</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
38.15pt;text-align:justify;text-indent:-.25in'><a name="_Toc24212356"></a><a
name="_Toc24326421"><span style='font-size:11.0pt;font-family:Symbol;
color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>audit our internal processes for compliance
with legal and contractual requirements or our internal policies;</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
38.15pt;text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:Symbol;color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>maintain appropriate business records;</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
38.15pt;text-align:justify;text-indent:-.25in'><a name="_Toc24212357"></a><a
name="_Toc24326422"><span style='font-size:11.0pt;font-family:Symbol;
color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>enforce the terms and conditions that govern
the Service; and</span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'> </span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
38.15pt;text-align:justify;text-indent:-.25in'><a name="_Toc24212358"></a><a
name="_Toc24326423"><span style='font-size:11.0pt;font-family:Symbol;
color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>prevent, identify, investigate and deter fraudulent,
harmful, unauthorized, unethical or illegal activity, including cyberattacks
and identity theft.</span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>  </span></h2>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>With
your consent.  </span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>In some cases, we may specifically ask for your consent to
collect, use or share your personal information, such as when required by law.</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>  </span></h2>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'><span
style='color:black'>When we no longer require the personal information we have
collected about you, we may either delete it, anonymize it, or isolate it from
further processing.  </span></p>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212359"></a><a name="_Toc24326424"></a><a
name="_Toc1737955"></a><a name="_3rdcrjn"></a><a name="_How_we_share"></a><span
style='font-size:16.0pt;font-family:"Calibri",sans-serif;color:black'>How we
share your personal </span><span style='font-size:16.0pt;font-family:"Calibri",sans-serif;
color:black'>information</span></h1>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'><span
style='color:black'>We may share your personal information with the following
parties and as otherwise described in this Privacy Policy or at the time of
collection.  </span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'><b><span
style='color:black'>Affiliates.  </span></b><span style='color:black'>Our
corporate parent, subsidiaries, and affiliates, for purposes consistent with
this Privacy Policy.</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;border:none'><b><span
style='color:black'>Service providers.</span></b><span style='color:black'>  Third
parties<b> </b>that provide services on our behalf or help us operate the Service
or our business (such as hosting, information technology, customer support, email
delivery, marketing, and website analytics). </span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;border:none'><b><span
style='color:black'>Advertising partners.  </span></b><span style='color:black'>Third-party
advertising companies for the </span><a href="#_Interest-based_advertising._">interest-based
advertising purposes</a><span style='color:black'> described above. </span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;border:none'><a
name="_cp_text_1_96"><b><span style='color:black'>Business and marketing
partners. </span></b></a><span style='color:black'>Third parties</span><b><span
style='color:black'> </span></b><span style='color:black'>with whom we
collaborate on joint activities (such as co-sponsors of contests and
promotions), with whom we have entered into joint marketing relationships or
other joint ventures, or who we think may offer you products or services that
you may enjoy.</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;border:none'><b><span
style='color:black'>Professional advisors.</span></b><span style='color:black'> 
Professional advisors, such as lawyers, auditors, bankers and insurers, where
necessary in the course of the professional services that they render to us.</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;border:none'><b><span
style='color:black'>Authorities and others. </span></b><span style='color:black'>Law
enforcement, government authorities, and private parties, as we believe in good
faith to be necessary or appropriate for the </span><a
href="#_Compliance_and_operations.">compliance and protection purposes</a><span
style='color:black'> described above. </span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;border:none'><b><span
style='color:black'>Business transferees.</span></b><span style='color:black'>&nbsp;
</span><a name="_cp_text_1_90"><span style='color:black'>Acquirers and other
relevant participants in </span></a><span style='color:black'>business </span><span
style='color:black'>transactions (or negotiations and diligence for such transactions)
involving a corporate divestiture, merger, consolidation, acquisition,
reorganization, sale or other disposition of all or any portion of the business
or assets of, or equity interests in, EH2</span> <span style='color:black'>or
our affiliates (including, in connection with a bankruptcy or similar
proceedings).  </span></p>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212360"></a><a name="_Toc24326425"></a><a
name="_Toc1737956"></a><a name="_Your_choices"></a><span style='font-size:16.0pt;
font-family:"Calibri",sans-serif;color:black'>Your </span><span
style='font-size:16.0pt;font-family:"Calibri",sans-serif;color:black'>choices</span><span
style='font-size:16.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span></h1>

<p class=UKBasicL1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;line-height:normal'><a name="_Toc529964456"></a><a
name="_Toc531681074"></a><a name="_35nkun2"></a><span lang=EN-GB
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>You have the
following choices with respect to your personal information. Users who are
located in the EEA or UK can find additional information about their rights
below.</span></p>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc1737957"></a><a name="_Toc24212361"></a><a
name="_Toc24326426"><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Access
or update your information. </span></a><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;font-weight:normal'>If you have registered for
an account with us through the Service, you may review and update certain account
information by logging into the account.</span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;font-weight:normal'> </span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc1737958"></a><a name="_Toc24212362"></a><a
name="_Toc24326427"></a><a name="Opt_out_of_marketing"><span style='font-size:
11.0pt;font-family:"Calibri",sans-serif'>Opt-out of marketing</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'> communications.  </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>You may opt-out of marketing-related emails by following
the opt-out or unsubscribe instructions at the bottom of the email, or by </span><a
href="#contacting_us"><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>contacting us</span></a><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;color:black;font-weight:normal'>.  Please note
that if you choose to opt-out of marketing-related emails, you may continue to
receive service-related and other non-marketing emails.  </span></h2>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;border:none'><a
name="_cp_change_90"><b><span style='color:black'>Cookies.</span></b></a><span
style='color:black'> Most browsers let you remove or reject cookies.  To do
this, follow the instructions in your browser settings.  Many browsers accept
cookies by default until you change your settings.  Please note that if you set
your browser to disable cookies, the Service may not work properly.  For more
information about cookies, including how to see what cookies have been set on
your browser and how to manage and delete them, visit </span><a
href="http://www.allaboutcookies.org">www.allaboutcookies.org</a><span
style='color:black'>. </span><a name="_cp_change_93"></a></p>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name=Advertisingchoices><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>Advertising choices</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>. </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'> </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> You can limit use of your information for interest-based
advertising by:</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
.5in;text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:Symbol;color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>Browser settings.  </span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;color:black;font-weight:normal'>Blocking third-party
cookies in your browser settings.</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
.5in;text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:Symbol;color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>Privacy browsers/plug-ins.</span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;color:black;font-weight:normal'> By using
privacy browsers or ad-blocking browser plug-ins that let you block tracking
technologies. </span></h2>

<h2 style='margin-top:5.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:
.5in;text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:Symbol;color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>Platform settings.</span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;color:black;font-weight:normal'> Google and Facebook offer
opt-out features that let you opt-out of use of your information for
interest-based advertising:</span></h2>

<h2 style='margin-top:5.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:
1.0in;text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Courier New";color:black;font-weight:normal'>o<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>Google: </span><a href="https://adssettings.google.com/"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>https://adssettings.google.com/</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span></h2>

<h2 style='margin-top:5.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:
1.0in;text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Courier New";color:black;font-weight:normal'>o<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>Facebook: </span><a
href="https://www.facebook.com/about/ads"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;font-weight:normal'>https://www.facebook.com/about/ads</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
.5in;text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:Symbol;color:black;font-weight:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>Ad industry tools.</span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;color:black;font-weight:normal'>  Opting out of
interest-based ads from companies participating in the following industry opt-out
programs: </span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
1.0in;text-indent:-.25in'><span style='font-size:11.0pt;font-family:"Courier New";
color:black;font-weight:normal'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>Network Advertising Initiative: </span><a
href="http://www.networkadvertising.org/managing/opt_out.asp"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>http://www.networkadvertising.org/managing/opt_out.asp</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
1.0in;text-align:justify;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Courier New";color:black;font-weight:normal'>o<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>Digital Advertising Alliance: </span><a
href="https://optout.aboutads.info/"><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;font-weight:normal'>https://optout.aboutads.info.  </span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span></h2>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'><span
style='color:black'>You will need to apply these opt-out settings on each
device from which you wish to opt-out.  </span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'><span
style='color:black'>Please note that we also may work with companies that offer
their own opt-out mechanisms and may not participate in the opt-out mechanisms
that we linked to above. </span></p>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc1737962"></a><a name="_Toc24212366"></a><a
name="_Toc24326431"></a><a name="_Toc531681075"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>Do Not Track</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.<a
name="_Toc529964458"></a><a name="_Toc531681076">  </a></span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>Some Internet browsers may be configured to send “Do Not
Track” signals to the online services that you visit.  We currently do not
respond to “Do Not Track” or similar signals.  To find out more about “Do Not
Track,” please visit </span><a href="http://www.allaboutdnt.com"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>http://www.allaboutdnt.com</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>.</span></h2>

<h2 style='margin-bottom:6.0pt;text-align:justify'><a name="_Toc1737963"></a><a
name="_Toc24212367"></a><a name="_Toc24326432"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>Declining to provide information. </span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>We
need to collect personal information to provide certain services.  If you do
not provide the information we identify as required or mandatory, we may not be
able to provide those services.</span></h2>

<h2 style='text-align:justify'><a name="_Toc1737964"></a><a name="_Toc24212368"></a><a
name="_Toc24326433"><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Third-party
platforms. </span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>If you choose to connect to the Service through your social
media account or other third-party platform, you may be able to use your
settings in your account with that platform to limit the information we receive
from it. If you revoke our ability to access information from a third-party
platform, that choice will not apply to information that we have already
received from that third party.</span><a name="_Payment_Information"></a></h2>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc529964460"></a><a name="_Toc531681078"></a><a
name="_Toc1737965"></a><a name="_Toc24212369"></a><a name="_Toc24326434"><span
style='font-size:16.0pt;font-family:"Calibri",sans-serif;color:black'>Other
sites and services</span></a><span style='font-size:16.0pt;font-family:"Calibri",sans-serif;
color:black'> </span></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212370"></a><a name="_Toc24326435"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>The Service may contain links to websites, mobile
applications, and other online services operated by third parties.  </span></a><a
name="_cp_text_1_371"><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>In addition, our content may be integrated into
web pages or other online services that are not associated with us.  </span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>These links and integrations are not an endorsement of, or
representation that we are affiliated with, any third party.  We do not control
websites, mobile applications or online services operated by third parties, and
we are not responsible for their actions nor are they subject to this Privacy Policy.</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>We encourage you to read the privacy policies
of the other websites and mobile applications and online services you use.</span><a
name="_Toc1737966"></a><a name="_Toc24212371"></a><a name="_Toc24326436"></a></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><span style='font-size:16.0pt;font-family:"Calibri",sans-serif;
color:black'>Security</span><span style='font-size:16.0pt;font-family:"Calibri",sans-serif;
color:black'> <a name="_Toc529964461"></a><a name="_Toc531681079"></a><a
name="_2jxsxqh"></a></span></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212372"></a><a name="_Toc24326437"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>We employ a number of technical, organizational and
physical safeguards designed to maintain the security of our Service and
prevent unauthorized access, loss, misuse, alteration, destruction or damage to
the personal information we collect.  However, security risk is inherent in all
internet and information technologies and the transmission of personal
information via the internet is not completely secure. Although we will do our
best to protect your personal information, we cannot guarantee the security of
your personal information transmitted through the Service.</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc1737967"></a><a name="_Toc24212373"></a><a
name="_Toc24326438"><span style='font-size:16.0pt;font-family:"Calibri",sans-serif;
color:black'>International data transfer</span></a></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212374"></a><a name="_Toc24326439"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>We are headquartered in the United States and may use service
providers that operate in other countries. Your personal information may be transferred
to the United States or other locations where privacy laws may not be as
protective as those in your state, province, or country.</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>  </span></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>Users in the UK and the EEA should read the
important information provided in the EEA/UK supplemental notice </span><a
href="#eurpean"><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal;text-decoration:none'>below</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> about transfer of personal information outside of the EEA
and UK, as applicable. </span></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc1737968"></a><a name="_Toc24212375"></a><a
name="_Toc24326440"><span style='font-size:16.0pt;font-family:"Calibri",sans-serif;
color:black'>Children</span></a><a name="_Toc529964462"></a><a
name="_Toc531681080"></a><a name="_z337ya"></a><span style='font-size:16.0pt;
font-family:"Calibri",sans-serif'>  </span></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212376"></a><a name="_Toc24326441"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>The
Service is not intended for use by children under 18 years of age. We do not
knowingly collect personal information from children under 18. If we learn that
we have collected or received personal information </span></a><a
name="_cp_text_1_139"><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>through the Service</span></a><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;font-weight:normal'> </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>from
a child under 18 without the consent of the child’s parent or guardian as
required by law, we will delete it.</span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;font-weight:normal'> </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>We
encourage parents or guardians with concerns, or who believe we have collected
personal information from children under the age of 18, to contact us. </span></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc529964463"></a><a name="_Toc531681081"></a><a
name="_Toc1737969"></a><a name="_Toc24212377"></a><a name="_Toc24326442"><span
style='font-size:16.0pt;font-family:"Calibri",sans-serif;color:black'>Changes
to this Privacy Policy</span></a><span style='font-size:16.0pt;font-family:
"Calibri",sans-serif;color:black'> </span></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212378"></a><a name="_Toc24326443"></a><a
name="_Toc20672919"></a><a name="_Toc20673000"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;color:black;font-weight:normal'>We reserve the
right to modify this Privacy Policy at any time. </span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>If
we make material changes to this Privacy Policy, we will notify you by updating
the date of this Privacy Policy and posting it on the Service. </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>If</span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'> required by law we will also provide notification of
changes in another way that we believe is reasonably likely to reach you, such
as via email <span style='color:black'>or another manner through the Service.</span></span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>  </span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>Any modifications to this Privacy Policy will be effective
upon our posting the modified version (or as otherwise indicated at the time of
posting). We recommend that you check this Privacy Policy periodically for any
updates or changes.  </span></h1>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212379"></a><a name="_Toc24326444"></a><a
name="_Toc1737970"></a><a name="hoe_to_contact_us"></a><a name="_How_to_Contact"></a><span
style='font-size:16.0pt;font-family:"Calibri",sans-serif'>How to <a
name="contacting_us">contact </a></span><span style='font-size:16.0pt;
font-family:"Calibri",sans-serif'>us</span></h1>

<p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Email</b>: info@eh2.com </p>

<p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Mail</b>: 1 Strathmore Road, Natick, Massachusetts 01760<a
name="_2xcytpi"></a><a name="_4i7ojhp"></a></p>

<h1 style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><a name="_Toc24212383"></a><a name="_Toc24326474"></a><a
name="_cp_text_1_132"></a><a name=eurpean></a><a name="_Notice_to_European"></a><a
name="_EEA/UK_GDPR_Supplemental"></a><span style='font-size:16.0pt;font-family:
"Calibri",sans-serif'>EEA/UK GDPR Supplemental Notice  </span></h1>

<p class=UKBasicL1 style='margin-left:0in;line-height:normal'><a
name="_cp_text_2_1361"><span lang=EN-GB style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>If you are located in the EEA or the UK, and access our
Services, this EEA/UK GDPR supplemental notice applies to you. </span></a></p>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><a name="_Toc1737976"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>Personal information</span></a></h2>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>References to “personal information” in this Privacy Policy
are equivalent to “personal data” governed by European data protection </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>legislation.
</span></h2>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><a name="_Toc531681088"></a><a name="_Toc1737977"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Controller</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'> </span></h2>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'>EH2 is the controller of your personal
information covered by this Privacy Policy for purposes of European data
protection legislation (i.e., the </span><a
href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&amp;from=EN"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>EU
GDPR</span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'> and the  ‘</span><a
href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/969514/20201102_-_GDPR_-__MASTER__Keeling_Schedule__with_changes_highlighted__V4.pdf"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>UK
GDPR’</span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'> (as and where applicable, the “</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>GDPR</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>”)).  See the ‘</span><a href="#hoe_to_contact_us"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>How
to contact us’</span></a><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;font-weight:normal'> section above for our contact details.</span></h2>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><a name="_Toc531681089"></a><a name="_Toc1737978"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>EU/UK Representative</span></a></h2>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>EH2 has appointed DataRep (12 Northbrook Road, Dublin, D06
E8W5, Ireland)</span><span style='font-size:11.5pt;font-family:"Open Sans",sans-serif;
color:#7A7A7A;background:white'> </span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;font-weight:normal'>as its EU representative,
which can be contacted at <a name="_Hlk109379815"></a></span><a
href="mailto:datarequest@datarep.com"><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;font-weight:normal'>datarequest@datarep.com</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;font-weight:normal'>
or </span><a href="mailto:contact@datarep.com"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;font-weight:normal'>contact@datarep.com</span></a></h2>

<p class=MsoNormal style='text-align:justify'>EH2 has appointed DataRep (12
Northbrook Road, Dublin, D06 E8W5, Ireland) as its UK representative, which can
be contacted at <a href="mailto:datarequest@datarep.com">datarequest@datarep.com</a>
or <a href="mailto:contact@datarep.com">contact@datarep.com</a> </p>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>[Data
Protection Officer</span></h2>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>[EH2’s Data Protection Officer can be contacted at [DPO’s
contact details]]. </span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></h2>

<h2 style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Our
legal bases for processing. </span></h2>

<p class=UKBasicL1 style='margin-left:0in;line-height:normal'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Where relevant under
applicable laws, all processing of your personal information will be justified
by a &quot;lawful ground&quot; for processing as set out in the table below.</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 width=671
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=274 valign=top style='width:205.65pt;border:solid windowtext 1.0pt;
  background:#ACB9CA;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:
  5.0pt;margin-left:0in;text-align:justify'><b><span style='color:black'>Processing
  purpose</span></b></p>
  <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:
  5.0pt;margin-left:0in;text-align:justify'><b><span style='color:black'>&nbsp;</span></b></p>
  </td>
  <td width=397 valign=top style='width:297.85pt;border:solid windowtext 1.0pt;
  border-left:none;background:#ACB9CA;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:
  5.0pt;margin-left:0in;text-align:justify'><b><span style='color:black'>Legal
  basis</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=274 valign=top style='width:205.65pt;border:solid windowtext 1.0pt;
  border-top:none;background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:
  5.0pt;margin-left:0in;text-align:justify'><span style='color:black'>We need
  to process your personal information to operate our Services, including managing
  your account, responding to your requests or inquiries, providing you with
  access to content or information you requested, etc.</span></p>
  </td>
  <td width=397 valign=top style='width:297.85pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:
  5.0pt;margin-left:0in;text-align:justify'>The processing of your personal
  information is necessary to perform the contract governing the provision of
  our Services or to take the necessary steps prior to signing up for the
  Services.</p>
  </td>
 </tr>
 <tr>
  <td width=274 valign=top style='width:205.65pt;border:solid windowtext 1.0pt;
  border-top:none;background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:
  6.0pt;margin-left:0in;text-align:justify'><span style='color:black'>We may
  need to process your personal information for additional purposes, such as:</span></p>
  <p class=Bullet1><span style='font-size:11.0pt;font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>Research
  and development purposes</span></p>
  <p class=Bullet1><span style='font-size:11.0pt;font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>To
  ensure access and maintenance of our Services, and to ensure their proper
  functioning</span></p>
  <p class=Bullet1><span style='font-size:11.0pt;font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>For
  marketing purposes</span></p>
  <p class=Bullet1><span style='font-size:11.0pt;font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><a
  href="#_2s8eyo1"><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
  color:black;text-decoration:none'>For compliance, fraud prevention and safety</span></a></p>
  <p class=Bullet1><span style='font-size:11.0pt;font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><a
  href="https://www.johnelliott.com/pages/b-privacy-policy-2018-b-br-last-updated-august-30-2018"><span
  style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
  text-decoration:none'>For sharing your personal information with third
  parties as described in this Privacy Policy</span></a></p>
  <p class=Bullet1><span style='font-size:11.0pt;font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>To
  disclose your personal information to a prospective or actual purchaser or
  seller in the context of a merger, acquisition or other reorganization or
  sale of our business or assets. </span></p>
  <p class=Bullet1><span style='font-size:11.0pt;font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>For the
  collection of statistical information about the use of the Services</span></p>
  <p class=Bullet1><span style='font-size:11.0pt;font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>To
  protect our interests as a company, for different purposes, such as:</span></p>
  <p class=Bullet2 style='margin-left:.75in'><span style='font-size:11.0pt;
  font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
  color:black'>Enforcement of the Terms of Service </span></p>
  <p class=Bullet2 style='margin-left:.75in'><span style='font-size:11.0pt;
  font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
  color:black'>Assess claims that any content violates the rights of
  third-parties</span></p>
  <p class=MsoListParagraph style='margin-left:7.5pt;text-align:justify;
  text-indent:-9.0pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;
  </span></span><span style='color:black'>Establishing or exercising our legal
  rights or defending against legal claims</span></p>
  </td>
  <td width=397 valign=top style='width:297.85pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:
  5.0pt;margin-left:0in;text-align:justify'>We rely on our legitimate interests
  to process your personal information when performing these processing
  activities. We do not use your personal information for these purposes where
  our interests are overridden by the impact on you.</p>
  </td>
 </tr>
 <tr>
  <td width=274 valign=top style='width:205.65pt;border:solid windowtext 1.0pt;
  border-top:none;background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:5.0pt;text-align:justify'><span
  style='color:black'>We are subject to certain legal obligations that may
  oblige us to disclose your personal information to courts, law enforcement or
  regulatory authorities.</span></p>
  </td>
  <td width=397 valign=top style='width:297.85pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:
  5.0pt;margin-left:0in;text-align:justify'>The processing of your personal
  information is necessary to comply with our legal obligations.</p>
  </td>
 </tr>
 <tr>
  <td width=274 valign=top style='width:205.65pt;border:solid windowtext 1.0pt;
  border-top:none;background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:
  5.0pt;margin-left:0in;text-align:justify'><span style='color:black'>To
  facilitate your job application or for other reasons for which we have
  requested your consent </span></p>
  </td>
  <td width=397 valign=top style='width:297.85pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:
  5.0pt;margin-left:0in;text-align:justify'><span style='color:black'>Processing
  is based on your consent.  Where we rely on your consent you have the right
  to withdraw it any time in the manner indicated when you consent or in the Services.
  </span></p>
  </td>
 </tr>
</table>

<p class=UKHeading1L2 style='margin:0in;text-indent:0in'><a name="_Toc1737979"></a><a
name="_Toc531681090"><span style='font-size:11.0pt;line-height:125%;font-family:
"Calibri",sans-serif'>&nbsp;</span></a></p>

<p class=UKHeading1L2 style='margin-left:0in;text-indent:0in'><b><span
style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>Your
rights</span></b></p>

<p class=UKBasicL1 style='margin-left:0in'><span lang=EN-GB style='font-size:
11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>Under applicable EU
and UK data protection laws, you have the following rights in respect of your
personal information:</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>to
obtain information about how and on what basis your personal information is
processed and to obtain a copy;</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>to
rectify inaccurate personal information;</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>to
erase your personal information in limited circumstances where (i) you believe
that it is no longer necessary for us to hold your personal information; (ii)
we are processing your personal information on the basis of legitimate
interests and you object to such processing, and we cannot demonstrate an
overriding legitimate ground for the processing; (ii) where you have provided
your personal information to us with your consent and you wish to withdraw your
consent and there is no other ground under which we can process your personal
information; and (iv) where you believe the personal information we hold about
you is being unlawfully processed by us;</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>to
restrict processing of your personal information where: (i) the accuracy of the
personal information is contested; (ii) the processing is unlawful but you
object to the erasure of the personal information; (iii) we no longer require
the personal information for the purposes for which it was collected, but it is
required for the establishment, exercise or defense of a legal claim; or (iv)
you have objected to us processing your personal information based on our
legitimate interests and we are considering your objection;</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>to
object to decisions which are based solely on automated processing or profiling.</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>where
you have provided your personal information to us with your consent, to ask us
for a copy of this information in a structured, machine-readable format and to
ask us to share (port) this information to another data controller; or</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>to
obtain a copy of or access to safeguards under which your personal information
is transferred outside of the EEA.</span></p>

<p class=UKBasicL1 style='margin-left:0in'><span lang=EN-GB style='font-size:
11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>Under certain
circumstances, you may have the right to object, on grounds relating to your
particular situation, to the processing of your personal information by us and
we may be required to no longer process your personal information. Moreover, if
your personal information is processed for direct marketing purposes, you have
the right to object at any time to the processing of personal information
concerning you for such marketing, which includes profiling to the extent that
it is related to such direct marketing. In this case your personal information
will no longer be processed for such purposes by us.</span></p>

<p class=UKBasicL1 style='margin-left:0in'><span lang=EN-GB style='font-size:
11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>When you request us
to provide the personal information we collect and process about you, we will
ask you for additional data to confirm your identity and for security purposes,
before disclosing data requested by you. We reserve the right to charge a fee
where permitted by law. We will decline to process requests that jeopardize the
privacy of others, are extremely impractical, or would cause us to take any
action that is not permissible under applicable laws. Additionally, as
permitted by applicable laws, we will retain where necessary certain personal
information for a limited period of time for record-keeping, accounting and
fraud prevention purposes.</span></p>

<p class=UKBasicL1 style='margin-left:0in'><span lang=EN-GB style='font-size:
11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>You have the right to
lodge a complaint with a supervisory authority in relation to the processing of
your personal information, however, we invite you to contact us first. Please
note that the right of access and the right to erasure do not constitute
absolute rights and the interests of other individuals may restrict your right
of access or erase in accordance with local laws.</span></p>

<h1 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>How
long do we keep your personal information</span></h1>

<h1 style='margin-top:12.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:
0in;text-align:justify;line-height:115%'><span lang=EN-GB style='font-size:
11.0pt;line-height:115%;font-family:"Calibri",sans-serif;color:black;
font-weight:normal'>We apply a general rule of keeping personal information
only for so long as is required to fulfill the purpose for which it was
collected. However, in some circumstances, we will retain your personal
information for longer periods of time. We will retain personal information for
the following purposes: (i) as long as it is necessary and relevant for our
operations and to provide our Service, e.g. so that we have an accurate record
of your dealings with us in the event of any complaints or challenge; and (ii)
in relation to personal information from closed accounts to comply with
applicable laws, prevent fraud, collect any fees owed, resolve disputes,
troubleshoot problems, assist with any investigation, enforce our Service terms
and take other actions as permitted by law. </span></h1>

<p class=UKHeading1L2 style='margin-top:12.0pt'><b><span style='font-size:11.0pt;
line-height:125%;font-family:"Calibri",sans-serif'>Cross-border data transfer</span></b></p>

<p class=UKBasicL1 style='margin-left:0in'><span lang=EN-GB style='font-size:
11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>Your personal
information may be transferred and stored outside your place of residence, to
countries which are subject to different standards of data protection. In
particular, if you live in the EEA or UK, you should be aware that your
personal information may be shared with, and transferred to, EH2 affiliates and
third-party service providers who are located outside the EEA or UK. We will
take appropriate steps to ensure that transfers of personal information are in
accordance with applicable law and carefully managed to protect your privacy
rights and interests and transfers are limited to countries which are
recognised as providing an adequate level of legal protection or where we can
be satisfied that alternative arrangements are in place to protect your privacy
rights.&nbsp; To this end:</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>we
will ensure that transfers within EH2 and its affiliates will be covered by an
agreement entered into by members of EH2 (an intra-group agreement) which
contractually obliges each member to ensure that personal information receives
an adequate and consistent level of protection wherever it is transferred
within the EH2 group;</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>where
we transfer your personal information outside EH2 or to third parties who help
provide our Services, we will obtain contractual commitments from them to
protect your personal information; and</span></p>

<p class=UKHeading1L3><span style='font-size:11.0pt;line-height:125%;
font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>where
we receive requests for information from law enforcement or regulators, we will
carefully validate these requests before personal information is disclosed.</span></p>

<p class=UKBasicL1 style='margin-left:0in'><span lang=EN-GB style='font-size:
11.0pt;line-height:125%;font-family:"Calibri",sans-serif'>You have a right to
contact us for more information about the safeguards we have put in place
(including a copy of relevant contractual commitments) to ensure the adequate
protection of your personal information when this is transferred as mentioned
above.</span></p>

<h2 style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
0in;text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
font-weight:normal'>&nbsp;</span></h2>

</div>

</body>

</html>
  `;
}
