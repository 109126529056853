import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';

import { useFormMessageStyles, FormMessageKind } from './form-message.styles';

export interface FormMessageProps extends TypographyProps {
  kind?: FormMessageKind;
}

export function FormMessage(props: FormMessageProps) {
  const classes = useFormMessageStyles({ kind: props.kind });
  return (
    <Typography
      {...props}
      className={clsx(classes.root, props.className)}
    />
  );
}
