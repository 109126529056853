import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone, Accept } from 'react-dropzone';

export type { Accept };

export interface UseFileUploadProps {
  onChange?(data: FileUploadChangeData): void;
  maxSize?: number;
  accept: Accept;
}

export interface FileUploadChangeData {
  file: File | string;
  filename: string;
}

export const DEFAULT_MAX_SIZE = 2000000;

export function useFileUploadModel(props: UseFileUploadProps) {
  const { onChange } = props;

  const [file, setFile] = useState<string>('');
  const [filename, setFilename] = useState<string>('');

  const handleDropzoneDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const filePath = URL.createObjectURL(file);

      setFile(filePath);
      setFilename(file.name);

      if (onChange) {
        onChange({
          file,
          filename: file.name
        });
      }
    },
    [onChange]
  );

  const clearFile = useCallback(() => {
    setFile('');

    if (onChange) {
      onChange({
        file: '',
        filename: ''
      });
    }
  }, [onChange]);

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: props.accept,
    maxSize: props.maxSize || DEFAULT_MAX_SIZE,
    onDrop: handleDropzoneDrop,
  });

  useEffect(() => {
    if (file) {
      return () => {
        URL.revokeObjectURL(file);
      };
    }

    return undefined;
  }, [file]);

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    clearFile,
    file,
    filename
  };
}
