import { makeStyles } from '@material-ui/core/styles';
import { addAlpha } from '~/common/util/color';
import { eh2green } from '~/common/styles/constants';

export const useInsightTooltipStyles = makeStyles(theme => ({
  insightIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
  },

  insightTooltip: {
    background: addAlpha(eh2green, 0.90),
    cursor: "pointer",
  },

  hidden: {
    visibility: "hidden",
  }
}));