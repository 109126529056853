import { createTheme, Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export type { Theme };

export const borderRadius = 4;

// @ts-ignore
export const theme = createTheme({
  palette: {
    primary: { main: '#333' },
    secondary: red,
    success: {
      main: '#179541',
      light: '#92D050'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    fontSize: 14,
    subtitle1: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.54)',
    },
    h1: { fontSize: '2.5rem' },
    h2: { fontSize: '1.75rem'},
    h3: { fontSize: '1.25rem' },
    h4: { fontSize: '1.05rem' },
    h5: { fontSize: '1.05rem' },
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginBottom: 2,
        marginTop: 2
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius,
        '& $notchedOutline': {
          // padding: 0,
        },
      },
      adornedStart: {
        // paddingLeft: 12
      },
      adornedEnd: {
        // paddingRight: 6
      },
      multiline: { borderRadius },
    },
    MuiInputLabel: {
      outlined: {
        '&$shrink': {
          // transform: 'translate(4px, -6px) scale(0.75)',
          whiteSpace: 'nowrap'
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius,
        fontSize: '.75rem',
        fontWeight: 'normal'
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: borderRadius * 2
      }
    },
    MuiChip: {
      root: {
        transition: undefined,
      },
      deleteIcon: {
        width: undefined,
        height: undefined,
      },
      clickable: {
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiSwitch: {
      thumb: {
        border: 'solid 1px #333'
      }
    },
    MuiRadio: {
      root: {
        padding: 4
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true // No ripple on the whole application
    },
    MuiDialog: {
      transitionDuration: 0,
    },
    MuiPopover: {
      PaperProps: { square: true },
    },
    MuiTooltip: {
      // enterDelay: 1200,
      arrow: true,
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiButton: {
      disableRipple: true,
      variant: 'contained'
    },
    MuiTextField: {
      variant: 'outlined',
      margin: 'dense',
      InputLabelProps: {
        shrink: true,
      },
    },
    MuiFormControl: {
      variant: 'outlined',
      margin: 'dense',
    },
    MuiCheckbox: {
      color: 'primary'
    },
    MuiRadio: {
      color: 'primary',
    }
  },
});
