import { useEffect, useState } from 'react';
import { useManualQuery } from 'graphql-hooks';

import { useAsyncState, UseAsyncStateResult } from '~/common/hooks/use-async-state';
import { useAuth } from '~/app/auth';
import { checkEntitlement } from '~/app/service';

import { useNextgenIntegrationContext } from '../hooks/nextgen-integration';

export type UseIsEntitledResult = [boolean, boolean, UseAsyncStateResult];

export function useIsEntitled(featureName: string): UseIsEntitledResult {
  const { authenticated, appUser } = useAuth();
  const [error, setError] = useState();
  const [canView, setCanView] = useState(false);

  const [getCurrentUserAuthorization, query] = useManualQuery(`
    query currentUserAuthorization($params: CurrentUserAuthorizationParams!) {
      currentUserAuthorization(params: $params) {
        isAuthorized
      }
    }
  `, {
    
  });

  const entitlementsRetrieval = useAsyncState();
  const { isNextgenIntegrated } = useNextgenIntegrationContext();

  useEffect(() => {
    if (appUser?.id) {
      entitlementsRetrieval.begin();
      if (isNextgenIntegrated) {
        getCurrentUserAuthorization({
          variables: {
            params: {
              userPermissionIdOrName: featureName,
            }
          }
        })
          .then(result => {
            setCanView(result.data.currentUserAuthorization.isAuthorized);
            entitlementsRetrieval.succeeded();
          })
          .catch((error) => {
            setError(error);
            entitlementsRetrieval.failed(error);
          });
      } else {
        checkEntitlement(featureName)
          .then(result => {
            setCanView(result.hasEntitlement);
            entitlementsRetrieval.succeeded();
          })
          .catch((error) => {
            setError(error);
            entitlementsRetrieval.failed(error);
          });
      }
      
    }
  }, [appUser?.id]);

  const pending = !authenticated || entitlementsRetrieval.isPending;

  return [canView, pending, entitlementsRetrieval];
}
