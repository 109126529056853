import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

export interface ChassisTitleViewProps {
  title: ReactNode;
}

export function ChassisTitleView(props: ChassisTitleViewProps) {
  return (
    <Typography style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
      {props.title}
    </Typography>
  )
}
