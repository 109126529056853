import { useMemo } from 'react';

import clsx from 'clsx';
import { IconButton, Typography } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import { Thumbnail } from '~/common/components/file-upload/thumbnail';
import { useFileUploadModel, UseFileUploadProps, Accept } from './use-file-upload-model';
import { useFileUploadStyles } from './file-upload.styles';

export type FileUploadProps = UseFileUploadProps & {
  className?: string;
  height?: number;
  accept: Accept;
};

export function FileUpload(props: FileUploadProps) {
  const { className, height, onChange, accept } = props;

  const {
    clearFile,
    getInputProps,
    getRootProps,
    isDragActive,
    file,
    filename
  } = useFileUploadModel({
    onChange,
    accept
  });

  const classes = useFileUploadStyles();

  const dropzoneContent = useMemo(() => {
    return (
      <div
        {...getRootProps({
          className: clsx(classes.fileDropzone, {
            [classes.fileDropzoneActive]: isDragActive,
          }),
        })}
      >
        <input {...getInputProps()} />
        <Thumbnail icon={<InsertDriveFileIcon />} className={classes.fileEmpty}>
          Upload File
        </Thumbnail>
      </div>
    );
  }, [getRootProps, getInputProps, isDragActive, classes]);

  const filePreviewContent = useMemo(() => {
    return (
      <div className={classes.filePreview}>
        <InsertDriveFileIcon />
        <Typography>{filename}</Typography>
        <IconButton
          size="medium"
          className={classes.fileClearBtn}
          onClick={clearFile}
        >
          <ClearOutlinedIcon />
        </IconButton>
      </div>
    );
  }, [filename, clearFile, classes]);

  const content = file ? filePreviewContent : dropzoneContent;

  return (
    <div
      className={clsx(classes.fileContainer, className)}
      style={{ height: height }}
    >
      {content}
    </div>
  );
}
