import React from 'react';

import clsx from 'clsx';

import { useEmptyStyles } from '~/common/components/file-upload/thumbnail.styles';

export interface EmptyProps extends React.ComponentPropsWithoutRef<'div'> {
  icon?: React.ReactElement;
}

export function Thumbnail(props: EmptyProps) {
  const { children, className, icon, ...other } = props;

  const classes = useEmptyStyles();

  const iconElement =
    React.isValidElement(icon) &&
    React.cloneElement<any>(icon, {
      fontSize: 'large',
      ...(icon.props as object),
      className: clsx(classes.icon, (icon.props as any)?.className),
    });

  return (
    <div {...other} className={clsx(classes.root, className)}>
      {iconElement}
      <span className={classes.text}>{children}</span>
    </div>
  );
}
