import { createContext, ReactNode, useContext, useLayoutEffect, useState, } from 'react';

import { loadScript } from '~/common/components/google-maps/load-script';

export interface GoogleMapContextValue {
  apiKey?: string;
  mapId?: string;
}

const MapContext = createContext<GoogleMapContextValue>({});

export interface GoogleMapContextProviderProps {
  children: ReactNode;
  apiKey: string;
  mapId: string;
}

export function GoogleMapContextProvider({
  children,
  apiKey,
  mapId,
}: GoogleMapContextProviderProps) {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useLayoutEffect(() => {
    (async () => {
      const isLoaded = await loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
        'google-map-places'
      );

      setScriptLoaded(isLoaded);
    })();
  }, [apiKey]);

  if (!scriptLoaded) {
    return null;
  }

  return (
    <MapContext.Provider value={{ apiKey, mapId }}>
      {children}
    </MapContext.Provider>
  );
}

export function useGoogleMapApiKey() {
  const apiKey = useContext(MapContext).apiKey;
  if (!apiKey) {
    throw new Error('google map apiKey missing from context');
  }
  return apiKey;
}

export function useGoogleMapId() {
  const mapId = useContext(MapContext).mapId;
  if (!mapId) {
    throw new Error('google mapId missing from context');
  }
  return mapId;
}
