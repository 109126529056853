export const loadScript = async (src: string, id: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (typeof window === undefined) {
      resolve(false);
    }

    if (document.querySelector(`#${id}`)) {
      resolve(true);
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;

    script.onload = () => {
      resolve(true);
    };

    const head = document.querySelector('head');
    head?.appendChild(script);
  });
};
