import React, { useRef, useState, HTMLAttributes } from 'react';
import useResizeObserver from '@react-hook/resize-observer';


export interface BreakpointsProps extends HTMLAttributes<HTMLDivElement> {
  breakpoints: Breakpoint[];
  children: BreakpointsRender;
}

export interface Breakpoint {
  name: string;
  min: number;
  max: number;
}

export type BreakpointsRender = (props: { breakpoint?: string }) => JSX.Element;

export function Breakpoints({ breakpoints, children, ...props }: BreakpointsProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [breakpointName, setBreakpointName] = useState<string|undefined>();

  useResizeObserver(ref, (e) => {
    for (const breakpoint of breakpoints) {
      if (e.contentRect.width >= breakpoint.min && e.contentRect.width <= breakpoint.max) {
        setBreakpointName(breakpoint.name);
        return;
      }
    }
    setBreakpointName(undefined);
  });

  return (
    <div {...props} ref={ref}>
      {children({ breakpoint: breakpointName })}
    </div>
  )
}
