import { useState, useCallback } from 'react';

import { useAsyncState } from '../../../common/hooks';
import * as service from '../../service';

export function useTos() {
  const [isTosAccepted, setIsTosAccepted] = useState<boolean>(false);
  const checkAsyncState = useAsyncState();

  const checkTosAcceptance = useCallback(() => {
    checkAsyncState.begin();
    service.checkTosAcceptance()
      .then(result => {
        checkAsyncState.succeeded();
        setIsTosAccepted(result.accepted);
      })
      .catch(() => checkAsyncState.failed());
  }, []);

  const acceptanceAsyncState = useAsyncState();

  const acceptTos = useCallback(() => {
    acceptanceAsyncState.begin();
    service.acceptTos()
      .then(() => {
        acceptanceAsyncState.succeeded();
        setIsTosAccepted(true);
      })
      .catch(() => acceptanceAsyncState.failed())
  }, []);

  return {
    isTosAccepted,
    checkTosAcceptance,
    isCheckPending: checkAsyncState.isPending,
    isCheckResolved: checkAsyncState.isResolved,
    checkError: checkAsyncState.error,
    acceptTos,
    isAcceptancePending: acceptanceAsyncState.isPending,
    isAcceptanceResolved: acceptanceAsyncState.isResolved,
    acceptanceError: acceptanceAsyncState.error
  };
}
