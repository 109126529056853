import React, { ReactNode } from 'react';
import { ChassisTitleView } from './chassis-title.view';
import { ChassisV2, Logo } from '../../../common/components';

export interface ChassisV2ReadonlyContainerProps {
  title: ReactNode;
  children: ReactNode;
}

const noop = () => {};

export function ChassisV2ReadonlyContainer(props: ChassisV2ReadonlyContainerProps) {
  return (
    <ChassisV2
      isReadonly={true}
      title={<ChassisTitleView title={props.title} />}
      tos={<div />}
      showTos={false}
      topMenuProps={{
        privacyPolicyPath: '',
        changePassword: noop,
        logout: noop,
      }}
      sidebarProps={{
        logo: <Logo />,
        clickHandlers: {
          slcoh: noop,
          dlcoh: noop,
          offtaker: noop,
          gepb: noop,
          electrolysisManufacturingDashboard: noop,
          userAnalytics: noop,
          pce: noop,
        },
        mailTo: 'info@eh2.com'
      }}
      showApi={false}
    >
      <div>
        {props.children}
      </div>
    </ChassisV2>
  )
}
