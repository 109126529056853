import { makeStyles } from '@material-ui/core/styles';

export const useSharedStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    minWidth: 250
  },
  logo: {
    width: 200,
    margin: '0 auto',
    display: 'block',
    marginBottom: theme.spacing(2)
  },
  popoverContent: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: 16
  },
  popoverText: {
    maxWidth: 300
  }
}));
