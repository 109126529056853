import React, { ReactNode, useCallback, useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import mixpanel from 'mixpanel-browser';
import { GraphQLClient, ClientContext } from 'graphql-hooks';
import * as Sentry from "@sentry/react";

import { GoogleMapContextProvider } from '~/common/components/google-maps';
import { theme } from '~/common/styles/theme';

import { FirebaseAuthContextProvider, CreateUserParams } from '../../auth';
import { getUserByFirebaseUid, createUser as baseCreateUser } from '../../service';
import { NextgenIntegrationContextProvider } from '../../hooks/nextgen-integration';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCujhkf91SUA2ckjDg9lwPfx_3pf4cYyKo';
const GOOGLE_MAPS_MAP_ID = '6608899977f47226';
const ENABLE_MIXPANEL = process.env.NEXT_PUBLIC_ENABLE_MIXPANEL === 'true';
const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
const isNextgenIntegrated = process.env.NEXT_PUBLIC_IS_NEXTGEN_INTEGRATED === 'true';

const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV
if (!SENTRY_ENV)  {
  throw new Error('env var SENTRY_ENV is missing');
}

const serviceNextgenUrlBase = process.env.NEXT_PUBLIC_SERVICE_NEXTGEN_URL_BASE;
if (isNextgenIntegrated && !serviceNextgenUrlBase) {
  throw new Error('env var NEXT_PUBLIC_SERVICE_NEXTGEN_URL_BASE is missing')
}
const graphqlClient = new GraphQLClient({
  url: `${serviceNextgenUrlBase}/graphql`,
});

export interface AppContainerProps {
  children: ReactNode;
  dontAuthRedirect?: boolean;
}

export function AppContainer(props: AppContainerProps) {
  useEffect(() => {
    Sentry.init({
      environment: SENTRY_ENV,
      dsn: "https://afb12ce52d0a4d8f82033e7204e175bb@o1305368.ingest.us.sentry.io/6546576",
      tracesSampleRate: 1.0,
    });
  }, []);
  
  useEffect(() => {
    if (ENABLE_MIXPANEL && MIXPANEL_TOKEN) {
      mixpanel.init(MIXPANEL_TOKEN, { track_pageview: true, persistence: 'localStorage' });
    }
  }, [])

  const createUser = useCallback((data: CreateUserParams) => {
    return baseCreateUser({
      ...data,
      hasReadTos: data.hasReadTos as boolean
    })
  }, []);

  const setGraphqlClientAuthHeader = useCallback((token: string) => {
    graphqlClient.setHeaders({
      Authorization: `Bearer ${token}`
    });
  }, []);

  return (
    <NextgenIntegrationContextProvider isNextgenIntegrated={isNextgenIntegrated}>
      <ClientContext.Provider value={graphqlClient}>
        <FirebaseAuthContextProvider
          getUserByFirebaseId={getUserByFirebaseUid}
          createUser={createUser}
          onAuthenticated={setGraphqlClientAuthHeader}
          dontAuthRedirect={props.dontAuthRedirect}
        >
          <GoogleMapContextProvider apiKey={GOOGLE_MAPS_API_KEY} mapId={GOOGLE_MAPS_MAP_ID}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {props.children}
            </ThemeProvider>
          </GoogleMapContextProvider>
        </FirebaseAuthContextProvider>
      </ClientContext.Provider>
    </NextgenIntegrationContextProvider>
  )
}
