import React, { useState, useCallback } from 'react';
import { TextField, Button, Typography } from '@material-ui/core';
import { useAsync } from 'react-async';

import { useSharedStyles } from './shared.styles';

export interface ChangePasswordFormProps {
  logoUrl: string;
  submit: (newPassword: string, oldPassword: string) => Promise<unknown>;
  onSuccess: () => void;
  requireOldPassword: boolean;
}

export function ChangePasswordForm({ submit, ...props }: ChangePasswordFormProps) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const deferFn = useCallback(
    () => submit(newPassword, oldPassword),
    [submit, oldPassword, newPassword]
  );

  const { run: execSubmit, isPending, isRejected, isFulfilled } = useAsync({
    deferFn,
    onResolve: props.onSuccess
  });

  const isValid = (props.requireOldPassword ? oldPassword : true) && newPassword && newPassword === confirmNewPassword;

  const classes = useSharedStyles();

  return (
    <div className={classes.root}>
      <img
        src={props.logoUrl}
        alt="Electric Hydrogen Logo"
        className={classes.logo}
      />

      {props.requireOldPassword && (
        <TextField
          type="password"
          label="Old Password"
          value={oldPassword}
          onChange={e => setOldPassword(e.target.value)}
        />
      )}

      <TextField
        type="password"
        label="New Password"
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
      />
      <TextField
        type="password"
        label="Confirm New Password"
        onChange={e => setConfirmNewPassword(e.target.value)}
      />

      <Button
        color="primary"
        disabled={!isValid || isPending || isFulfilled}
        onClick={execSubmit}
      >Change Password</Button>

      {isFulfilled && (
      <Typography color="secondary" style={{ color: 'green' }}>Password changed!</Typography>
      )}

      {isRejected && (
      <Typography color="secondary">Unable to update password.</Typography>
      )}
    </div>
  );
}
