import React, { ReactNode } from 'react';

import { useDividerStyles } from './divider.styles';

export interface DividerProps {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  color?: string;
}

export function Divider({ left, center, right, color }: DividerProps) {
  const classes = useDividerStyles({ color });
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {left}
      </div>

      <div className={classes.lineContainer}>
        <div className={classes.line} />
      </div>

      <div>
        {center}
      </div>

      <div className={classes.lineContainer}>
        <div className={classes.line} />
      </div>

      <div className={classes.right}>
        {right}
      </div>
    </div>
  )
}
