import React, { ReactElement } from 'react';

import { useAuthFormLayoutStyles } from './auth-form-layout.styles';

export interface AuthFormLayoutProps {
  children: ReactElement;
}

export function AuthFormLayout(props: AuthFormLayoutProps) {
  const classes = useAuthFormLayoutStyles();
  return (
    <div className={classes.root}>
      {props.children}
    </div>
  )
}
