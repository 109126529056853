import '~/common/styles/global.css';
import type { AppProps } from 'next/app';

import { AppContainer } from '~/app';

function App({ Component, pageProps }: AppProps) {
  const { dontAuthRedirect, ...componentProps } = pageProps;
  
  return (
    <AppContainer dontAuthRedirect={dontAuthRedirect} >
      <Component {...componentProps} />
    </AppContainer>
  );
}

export default App;
