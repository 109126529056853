import { useCallback, useState } from 'react';

import { useAsyncState } from '~/common/hooks';
import { RegisterFormSubmitData } from '~/common/components';

import { useRegister as useBaseRegister, RegistrationError, RegistrationErrorCode } from '../../auth';

export function useRegistration() {
  const asyncState = useAsyncState();
  const baseRegister = useBaseRegister();
  const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined);

  const clearErrorMessage = () => setErrorMessage(undefined);

  const register = useCallback(async (data: RegisterFormSubmitData) => {
    asyncState.begin();
    clearErrorMessage();
    try {
      const result = await baseRegister(data.email, data.password);
      asyncState.succeeded();
      return result;
    } catch (err) {
      const errorMessage = getRegistrationErrorMessage(err as RegistrationError)
      setErrorMessage(errorMessage);
      asyncState.failed();
    }
  }, [asyncState, baseRegister]);

  return {
    register,
    errorMessage,
    clearErrorMessage,
    asyncState,
  };
}

function getRegistrationErrorMessage(error: RegistrationError) {
  switch (error.code) {
    case RegistrationErrorCode.WeakPassword:
      return 'Password is too weak';
    case RegistrationErrorCode.EmailTaken:
      return 'This email address is already registered';
    default:
      return 'An unknown error occurred';
  }
}
