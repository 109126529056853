export const GET = 'GET';
export const POST = 'POST';
export const DELETE = 'DELETE';
export const PATCH = 'PATCH';

export interface ErrorResponseData {
  message: string;
}

export async function parseJsonResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    const message = (await response.json() as ErrorResponseData).message
    throw new Error(message);
  }
  const result = await response.json();
  return result.data;
}

export interface HttpClientConfig {
  urlBase: string;
  getAuthorization: GetAuthorization;
}

export type GetAuthorization = () => Promise<string|undefined>;

export class EH2AppServiceClient {
  protected urlBase: string;
  protected getAuthorization: GetAuthorization;

  constructor(cfg: HttpClientConfig) {
    this.urlBase = cfg.urlBase;
    this.getAuthorization = cfg.getAuthorization;
  }

  protected getDefaultHeaders = async () => {
    const authorization = await this.getAuthorization();
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authorization}`,
    };
  }
}
