import { useState, useCallback } from 'react';

export interface UseAsyncStateProps<E> {
  preserveLastError?: boolean
  onError?: (error: E) => void;
}

export interface UseAsyncStateResult {
  isPending: boolean;
  isResolved: boolean;
  error: unknown;
  begin: () => void;
  succeeded: () => void;
  failed: (error?: any) => void; // fixme
  success: boolean;
  clearError: () => void;
}

export function useAsyncState<E = any>(props: UseAsyncStateProps<E> = {}): UseAsyncStateResult {
  const { preserveLastError } = props;

  const onError = props.onError || console.error; // todo: should console.error be dev only?

  const [isPending, setIsPending] = useState(false);
  const [isResolved, setIsResolved] = useState(false);
  const [error, setError] = useState<E>();

  const begin = useCallback(() => {
    setIsPending(true);
    if (!preserveLastError) {
      setError(undefined);
    }
  }, [preserveLastError]);

  // todo: rename to "succeed"
  const succeeded = useCallback(() => {
    setIsPending(false);
    setIsResolved(true);
    setError(undefined);
  }, []);

  // todo: ranem to "fail"
  const failed = useCallback((error?: E) => {
    setIsPending(false);
    setIsResolved(true);
    setError(error);
    onError?.(error);
  }, [onError]);

  const clearError = useCallback(() => {
    setError(undefined);
  }, []);

  return {
    isPending,
    isResolved,
    error,
    begin,
    succeeded,
    failed,
    success: isResolved && !error,
    clearError
  }
}
